import React, { ReactNode } from "react";
import css from "./NamespaceTag.module.css";

import clsx from "clsx";
import Link from "next/link";
import Image from "next/image";

export type linkingType = "namespace" | "nolink";
interface NamespaceTagInterface {
  icon: string;
  namespaceName: string;
  namespaceDisplayName: string;
  label?: ReactNode;
  iconSize?: number;
  linking?: linkingType;
  className?: string;
}
const NamespaceTag = ({
  icon,
  namespaceName,
  namespaceDisplayName,
  linking = "namespace",
  iconSize = 32,
  className = "",
}: NamespaceTagInterface) => {
  return (
    <div className={clsx(css.tagCont, className)}>
      {/* {label && <div className={css.tagLabel} >{label}</div>} */}

      <div className={clsx(css.tagWrap, className)}>
        <Image src={icon} alt="avatar" width={iconSize} height={iconSize} className={css.icon} crossOrigin="anonymous" />

        {linking == "nolink" && <span className={css.pkgName}>{namespaceDisplayName}</span>}
        {linking == "namespace" && (
          <Link className={css.pkgName} href={`/${namespaceName}`}>
            {namespaceDisplayName}
          </Link>
        )}
      </div>
    </div>
  );
};
export default NamespaceTag;

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { graphql } from "react-relay";

import { NotificationItemInvitCollabNsp_UserNotificationKindIncomingNamespaceInvite$key } from "@generated/NotificationItemInvitCollabNsp_UserNotificationKindIncomingNamespaceInvite.graphql";

import { NotificationItemInvitCollabNsp } from "./NotificationItemInvitCollabNsp";

dayjs.extend(relativeTime);

export const notifInvNsp_Fragment = graphql`
  fragment NotificationItemInvitCollabNsp_UserNotificationKindIncomingNamespaceInvite on UserNotification {
    id
    icon
    seenState
    createdAt
    kind {
      ... on UserNotificationKindIncomingNamespaceInvite {
        namespaceInvite {
          id
          createdAt
          declinedBy {
            globalName
            avatar
          }
          approvedBy {
            globalName
            avatar
          }
          requestedBy {
            globalName
            avatar
          }
          namespace {
            globalId
            globalName
            displayName
            avatar
          }
        }
      }
    }
  }
`;

export interface NotificationItemInvitCollabNspInterface {
  notifNspRef: NotificationItemInvitCollabNsp_UserNotificationKindIncomingNamespaceInvite$key | null;
}
export default NotificationItemInvitCollabNsp;

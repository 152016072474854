"use client"

import React, { memo, useEffect, useRef, useState } from "react";

import useIntersectionObserver from "@hooks/useIntersectionObserver";
import useUpdateEffect from "@hooks/useUpdateEffect";
import { cn } from "@libs/helper";

import Loading0 from "./Loading0";
import Loading1 from "./Loading1";
import Loading2 from "./Loading2";
import Loading3 from "./Loading3";
import styles from "./index.module.css";

interface NewLoadingProps extends React.HTMLAttributes<HTMLDivElement>, React.RefAttributes<HTMLDivElement> {
  onVisible?: () => void;
}

const NewLoading: React.FC<NewLoadingProps> = ({ onVisible, className, ...restProps }) => {
  const [state, setState] = useState(0),
    ref = useRef<HTMLDivElement | null>(null),
    entry = useIntersectionObserver(ref, {}),
    isVisible = entry?.isIntersecting;
  useUpdateEffect(() => {
    if (isVisible) {
      onVisible?.();
    }
  }, [isVisible, onVisible]);

  const loadingStates = [
    <Loading0 key={0} className={cn(styles.loadingIcon, styles.rotate)} />,
    <Loading1 key={1} className={cn(styles.loadingIcon, styles.rotate)} />,
    <Loading2 key={2} className={cn(styles.loadingIcon, styles.rotate)} />,
    <Loading3 key={3} className={cn(styles.loadingIcon, styles.rotate)} />,
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      if (state < 3) {
        setState((prevState) => prevState + 1);
      } else {
        clearInterval(interval);
      }
    }, 300);

    if (state >= 3) {
      setState(1);
    }

    return () => clearInterval(interval);
  }, [state, setState]);

  return (
    <div ref={ref} className={cn(styles.wrapper, className)} {...restProps}>
      {loadingStates[state]}
    </div>
  );
};

export default memo(NewLoading);

/**
 * @generated SignedSource<<2384977ab702defc4b34210849efb07c>>
 * @relayHash da7d04fcb6e0d80bedddad620a9239b8
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID da7d04fcb6e0d80bedddad620a9239b8

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AcceptPackageCollaboratorInviteInput = {
  clientMutationId?: string | null | undefined;
  inviteId: string;
};
export type NotificationItemInvitCollabPkg_AcceptInviteMutation$variables = {
  input: AcceptPackageCollaboratorInviteInput;
};
export type NotificationItemInvitCollabPkg_AcceptInviteMutation$data = {
  readonly acceptPackageCollaboratorInvite: {
    readonly packageCollaboratorInvite: {
      readonly approvedBy: {
        readonly username: string;
      } | null | undefined;
      readonly id: string;
      readonly package: {
        readonly id: string;
      };
    };
  } | null | undefined;
};
export type NotificationItemInvitCollabPkg_AcceptInviteMutation = {
  response: NotificationItemInvitCollabPkg_AcceptInviteMutation$data;
  variables: NotificationItemInvitCollabPkg_AcceptInviteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Package",
  "kind": "LinkedField",
  "name": "package",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationItemInvitCollabPkg_AcceptInviteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AcceptPackageCollaboratorInvitePayload",
        "kind": "LinkedField",
        "name": "acceptPackageCollaboratorInvite",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PackageCollaboratorInvite",
            "kind": "LinkedField",
            "name": "packageCollaboratorInvite",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "approvedBy",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NotificationItemInvitCollabPkg_AcceptInviteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AcceptPackageCollaboratorInvitePayload",
        "kind": "LinkedField",
        "name": "acceptPackageCollaboratorInvite",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PackageCollaboratorInvite",
            "kind": "LinkedField",
            "name": "packageCollaboratorInvite",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "approvedBy",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "da7d04fcb6e0d80bedddad620a9239b8",
    "metadata": {},
    "name": "NotificationItemInvitCollabPkg_AcceptInviteMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "e3eee438e4371bd6ee4462d145db721c";

export default node;

import Link from "next/link";
import React from "react";

// @ts-ignore
import logo from "@assets/images/logo.svg?url";
import GetStartedCode from "@components/GetStartedCode";
import { DISCORD_INVITE } from "@utils/constants";

interface FooterProps {
  showGetStarted?: boolean;
}
const Footer: React.FC<FooterProps> = ({ showGetStarted = false }) => {
  return (
    <div className="bg-gradient overflow-hidden">
      {/* <div className="bg-gradient relative"></div>  Trick not used as results not satisfactory */}
      {/* <div className="absolute inset-0 h-full bg-gradient flex bg-black"></div> */}
      {!showGetStarted ? null : <GetStartedCode />}
      <footer className="relative z-[0] px-8 md:px-10 xlg:px-5">
        <div className="mx-auto w-full max-w-[1072px] pb-6 pt-2 lg:pb-8">
          <div className="grid md:flex md:justify-between">
            <div className="order-2 mb-6 mt-6 sm:order-1 sm:mt-0 md:mb-0">
              <Link href="#" className="flex items-center">
                <img src={logo.src} width={124} height={36} className="mr-3 h-10" alt="FlowBite Logo" />
              </Link>
              <p className="text-dark-gray my-6 font-normal md:max-w-[160px]">Making software universally accessible</p>

              <div className="sm:justify-left mt-4 flex items-center space-x-6 sm:mt-0">
                <Link
                  href="https://twitter.com/wasmerio"
                  target="_blank"
                  className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
                >
                  <svg className="h-7 w-7" fill="#b6b7c5" viewBox="0 0 24 24" aria-hidden="true">
                    <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                  </svg>
                  <span className="sr-only">Twitter</span>
                </Link>
                <Link
                  href={DISCORD_INVITE}
                  className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
                  target="_blank"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 127.14 96.36" fill="#b6b7c5" width="24">
                    <g id="图层_2" data-name="图层 2">
                      <g id="Discord_Logos" data-name="Discord Logos">
                        <g id="Discord_Logo_-_Large_-_White" data-name="Discord Logo - Large - White">
                          <path d="M107.7,8.07A105.15,105.15,0,0,0,81.47,0a72.06,72.06,0,0,0-3.36,6.83A97.68,97.68,0,0,0,49,6.83,72.37,72.37,0,0,0,45.64,0,105.89,105.89,0,0,0,19.39,8.09C2.79,32.65-1.71,56.6.54,80.21h0A105.73,105.73,0,0,0,32.71,96.36,77.7,77.7,0,0,0,39.6,85.25a68.42,68.42,0,0,1-10.85-5.18c.91-.66,1.8-1.34,2.66-2a75.57,75.57,0,0,0,64.32,0c.87.71,1.76,1.39,2.66,2a68.68,68.68,0,0,1-10.87,5.19,77,77,0,0,0,6.89,11.1A105.25,105.25,0,0,0,126.6,80.22h0C129.24,52.84,122.09,29.11,107.7,8.07ZM42.45,65.69C36.18,65.69,31,60,31,53s5-12.74,11.43-12.74S54,46,53.89,53,48.84,65.69,42.45,65.69Zm42.24,0C78.41,65.69,73.25,60,73.25,53s5-12.74,11.44-12.74S96.23,46,96.12,53,91.08,65.69,84.69,65.69Z" />
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span className="sr-only">Discord</span>
                </Link>
                <Link
                  href="https://github.com/wasmerio/wasmer"
                  target="_blank"
                  className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
                >
                  <svg className="h-7 w-7" fill="#b6b7c5" viewBox="0 0 24 24" aria-hidden="true">
                    <path
                      fillRule="evenodd"
                      d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="sr-only">GitHub</span>
                </Link>
              </div>
            </div>
            <div className="order-1 grid min-w-[56%] grid-cols-1 gap-8 sm:order-2 sm:grid-cols-4 sm:gap-6">
              <div>
                <h2 className="mb-6 text-new-base font-semibold text-gray-900">Explore</h2>
                <ul className="text-dark-gray font-medium dark:text-gray-400">
                  <li className="mb-4">
                    <Link href="/explore" className="hover:underline">
                      Packages
                    </Link>
                  </li>
                  <li className="mb-4">
                    <Link href="/posts" className="hover:underline">
                      Blog
                    </Link>
                  </li>
                </ul>
              </div>
              <div>
                <h2 className="mb-6 text-new-base font-semibold text-gray-900">Products</h2>
                <ul className="text-dark-gray font-medium dark:text-gray-400">
                  <li className="mb-4">
                    <Link href="/products/runtime" className="hover:underline ">
                      Runtime
                    </Link>
                  </li>
                  <li className="mb-4">
                    <Link href="/products/registry" className="hover:underline">
                      Registry
                    </Link>
                  </li>
                  <li className="mb-4">
                    <Link href="/products/edge" className="hover:underline ">
                      Edge
                    </Link>
                  </li>
                </ul>
              </div>
              <div>
                <h2 className="mb-6 text-new-base font-semibold text-gray-900">Use cases</h2>
                <ul className="text-dark-gray font-medium dark:text-gray-400">
                  <li className="mb-4">
                    <Link href="/static-site-hosting" className="hover:underline">
                      Static Site Hosting
                    </Link>
                  </li>
                  <li className="mb-4">
                    <Link href="/php-hosting" className="hover:underline">
                      PHP Hosting
                    </Link>
                  </li>
                </ul>
              </div>
              <div>
                <h2 className="mb-6 text-new-base font-semibold text-gray-900">Company</h2>
                <ul className="text-dark-gray font-medium dark:text-gray-400">
                  <li className="mb-4">
                    <Link href="/about" className="hover:underline">
                      About
                    </Link>
                  </li>
                  <li className="mb-4">
                    <Link href="/values-and-culture" className="hover:underline">
                      Values & Culture
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <hr className="my-6 border-[#cdcdcd] sm:mx-auto lg:my-8" />
          <div className="flex sm:items-center sm:justify-between">
            <ul className="text-dark-gray flex font-medium sm:justify-between">
              <li>
                <Link href="#" className="mr-8 hover:underline">
                  Imprint
                </Link>
              </li>
              <li>
                <Link href="/policies/privacy" className="mr-8 hover:underline">
                  Privacy
                </Link>
              </li>
              <li>
                <Link href="/terms" className="mr-8 hover:underline">
                  Terms
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;

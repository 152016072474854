import React, { ReactNode } from "react";
import css from "./Card.module.css";
import clsx from "clsx";

interface CardInterface {
  children?: ReactNode;
  className?: string;
}

const Card = ({ className = "", children }: CardInterface) => {
  return <div className={clsx(css.card, className)}>{children}</div>;
};
export default Card;

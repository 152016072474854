import clsx from "clsx";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import Image from "next/image";
import React, { useCallback, useState } from "react";
import { graphql, useFragment, useMutation } from "react-relay";

import NotifIndicatorIcon from "@assets/icons/NotifIndicatorIcon";
import PackageTag from "@components/PackageCard/PackageTag";
import { ToasterAlertDefault, ToasterAlertError } from "@components/shared/ToasterAlert";
import { NotificationItemReqTransfertPkg_AcceptTransferMutation } from "@generated/NotificationItemReqTransfertPkg_AcceptTransferMutation.graphql";
import { NotificationItemReqTransfertPkg_RejectTransferMutation } from "@generated/NotificationItemReqTransfertPkg_RejectTransferMutation.graphql";
import { NotificationItemReqTransfertPkg_UserNotificationKindIncomingPackageInvite$key } from "@generated/NotificationItemReqTransfertPkg_UserNotificationKindIncomingPackageInvite.graphql";

import NotificationActionBlock from "../NotificationActionBlock";
import css from "./NotificationItemReqTransfertPkg.module.css";

dayjs.extend(relativeTime);

const notifInvNsp_Fragment = graphql`
  fragment NotificationItemReqTransfertPkg_UserNotificationKindIncomingPackageInvite on UserNotification {
    id
    icon
    seenState
    createdAt
    kind {
      ... on UserNotificationKindIncomingPackageTransfer {
        packageTransferRequest {
          id
          createdAt
          declinedBy {
            globalName
            avatar
          }
          approvedBy {
            globalName
            avatar
          }
          requestedBy {
            globalName
            avatar
          }
          package {
            owner {
              globalName
            }
            packageName
            icon
          }
        }
      }
    }
  }
`;

interface NotificationItemReqTransfertPkgInterface {
  notifPkgRef: NotificationItemReqTransfertPkg_UserNotificationKindIncomingPackageInvite$key | null;
}

const NotificationItemReqTransfertPkg = ({ notifPkgRef }: NotificationItemReqTransfertPkgInterface) => {
  const notifPkgData = useFragment(notifInvNsp_Fragment, notifPkgRef);
  const [actionTaken, setActionTaken] = useState<"waiting" | "accepted" | "rejected">("waiting");

  const [acceptTrsf, acceptingTrsf] = useMutation<NotificationItemReqTransfertPkg_AcceptTransferMutation>(graphql`
    mutation NotificationItemReqTransfertPkg_AcceptTransferMutation($input: AcceptPackageTransferRequestInput!) {
      acceptPackageTransferRequest(input: $input) {
        packageTransferRequest {
          id
          approvedBy {
            username
          }
          package {
            id
          }
        }
      }
    }
  `);

  const [rejectTrsf, rejectingTrsf] = useMutation<NotificationItemReqTransfertPkg_RejectTransferMutation>(graphql`
    mutation NotificationItemReqTransfertPkg_RejectTransferMutation($input: RemovePackageTransferRequestInput!) {
      removePackageTransferRequest(input: $input) {
        package {
          id
          name
          namespace
          pendingInvites {
            edges {
              node {
                id
                declinedBy {
                  username
                }
              }
            }
          }
          isTransferring
        }
      }
    }
  `);

  const handleOnAcceptInvit = useCallback(() => {
    const variables = {
      input: {
        packageTransferRequestId: notifPkgData?.kind?.packageTransferRequest?.id!,
      },
    };
    acceptTrsf({
      variables,
      onCompleted: (_response, errors) => {
        if (errors && errors.length) {
          ToasterAlertError({ message: errors[0].message });
        } else {
          ToasterAlertDefault({ message: "Package transfered" });
          setActionTaken("accepted");
        }
      },
      onError: (error) => {
        console.error(error);
        ToasterAlertError({ message: "An error while transfering " });
      },
    });
  }, [acceptTrsf, notifPkgData?.kind?.packageTransferRequest?.id]);

  const handleOnRejectInvit = useCallback(() => {
    const variables = {
      input: {
        packageTransferRequestId: notifPkgData?.kind?.packageTransferRequest?.id!,
      },
    };
    rejectTrsf({
      variables,
      onCompleted: (_response, errors) => {
        if (errors && errors.length) {
          ToasterAlertError({ message: errors[0].message });
        } else {
          ToasterAlertDefault({ message: "You rejected the transfer" });
          setActionTaken("rejected");
        }
      },
      onError: (error) => {
        console.error(error);
        ToasterAlertError({ message: "An error while rejecting transfer" });
      },
    });
  }, [rejectTrsf, notifPkgData?.kind?.packageTransferRequest?.id]);

  return (
    <div className={css.wrapper}>
      <div className={css.content}>
        <div className={css.iconWrap}>
          <Image
            className={css.icon}
            src={notifPkgData?.kind?.packageTransferRequest?.requestedBy.avatar!}
            width={32}
            height={32}
            alt="user"
          />
          <NotifIndicatorIcon
            className={clsx(css.indicator, {
              ["!important hidden"]: notifPkgData?.seenState != "UNSEEN",
            })}
          />
        </div>

        <div className={css.notifWrap}>
          <div className={css.messageBlock}>
            <div className={css.messageCont}>
              <span className={css.username}>{notifPkgData?.kind?.packageTransferRequest?.requestedBy.globalName}</span>
              <span className={css.message}>has requested to transfer</span>
              <PackageTag
                icon={notifPkgData?.kind?.packageTransferRequest?.package.icon!}
                owner={notifPkgData?.kind?.packageTransferRequest?.package.owner.globalName!}
                packageName={notifPkgData?.kind?.packageTransferRequest?.package.packageName!}
                linking="package"
                iconSize={22}
              />
            </div>
          </div>

          <div className={css.time}>{dayjs(notifPkgData?.createdAt).fromNow()}</div>
          {actionTaken == "waiting" && (
            <NotificationActionBlock
              accepting={acceptingTrsf}
              rejecting={rejectingTrsf}
              onAccept={handleOnAcceptInvit}
              onReject={handleOnRejectInvit}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default NotificationItemReqTransfertPkg;

/* eslint-disable react-hooks/exhaustive-deps */
import clsx from "clsx";
import dayjs from "dayjs";
import Image from "next/image";
import React, { useCallback, useState } from "react";
import { graphql, useFragment, useMutation } from "react-relay";

import NotifIndicatorIcon from "@assets/icons/NotifIndicatorIcon";
import NamespaceTag from "@components/PackageCard/NamespaceTag";
import { ToasterAlertDefault, ToasterAlertError } from "@components/shared/ToasterAlert";
import { NotificationItemInvitCollabNsp_AcceptInvitMutation } from "@generated/NotificationItemInvitCollabNsp_AcceptInvitMutation.graphql";
import { NotificationItemInvitCollabNsp_RejectInvitMutation } from "@generated/NotificationItemInvitCollabNsp_RejectInvitMutation.graphql";

import NotificationActionBlock from "../NotificationActionBlock";
import css from "./NotificationItemInvitCollabNsp.module.css";
import { NotificationItemInvitCollabNspInterface, notifInvNsp_Fragment } from ".";

export const NotificationItemInvitCollabNsp = ({ notifNspRef }: NotificationItemInvitCollabNspInterface) => {
  const notifNspData = useFragment(notifInvNsp_Fragment, notifNspRef);

  const [actionTaken, setActionTaken] = useState<"waiting" | "accepted" | "rejected">("waiting");

  const [acceptNspInvit, acceptingNspInvit] = useMutation<NotificationItemInvitCollabNsp_AcceptInvitMutation>(graphql`
    mutation NotificationItemInvitCollabNsp_AcceptInvitMutation($input: AcceptNamespaceCollaboratorInviteInput!) {
      acceptNamespaceCollaboratorInvite(input: $input) {
        namespaceCollaboratorInvite {
          id
          accepted {
            id
          }
          namespace {
            collaborators {
              edges {
                node {
                  user {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const [rejectNspInvit, rejectingNspInvit] = useMutation<NotificationItemInvitCollabNsp_RejectInvitMutation>(graphql`
    mutation NotificationItemInvitCollabNsp_RejectInvitMutation($input: RemoveNamespaceCollaboratorInviteInput!) {
      removeNamespaceCollaboratorInvite(input: $input) {
        namespace {
          pendingInvites {
            edges {
              node {
                user {
                  id
                }
              }
            }
          }
        }
      }
    }
  `);

  const handleOnAcceptInvit = useCallback(() => {
    const variables = {
      input: {
        inviteId: notifNspData?.kind?.namespaceInvite?.id!,
      },
    };
    acceptNspInvit({
      variables,
      onCompleted: (_response, errors) => {
        if (Array.isArray(errors)) {
          ToasterAlertError({ message: errors[0].message });
        } else {
          ToasterAlertDefault({ message: "Namespace invitation accepted" });
          setActionTaken("accepted");
        }
      },
      onError: (error) => {
        console.error(error);
        ToasterAlertError({ message: "An error while accepting invitation" });
      },
    });
  }, [acceptNspInvit, notifNspData]);

  const handleOnRejectInvit = useCallback(() => {
    const variables = {
      input: {
        inviteId: notifNspData?.kind?.namespaceInvite?.id!,
      },
    };
    rejectNspInvit({
      variables,
      onCompleted: (_response, errors) => {
        if (Array.isArray(errors)) {
          ToasterAlertError({ message: errors[0].message });
        } else {
          ToasterAlertDefault({ message: "Namespace invitation rejected" });
          setActionTaken("rejected");
        }
      },
      onError: (error) => {
        console.error(error);
        ToasterAlertError({ message: "An error while rejecting invitation" });
      },
    });
  }, [rejectNspInvit, notifNspData]);

  return (
    <div className={css.wrapper}>
      <div className={css.content}>
        <div className={css.iconWrap}>
          <Image
            className={css.icon}
            src={notifNspData?.kind?.namespaceInvite?.requestedBy?.avatar!}
            width={32}
            height={32}
            alt={notifNspData?.kind?.namespaceInvite?.requestedBy?.globalName!}
          />
          <NotifIndicatorIcon
            className={clsx(css.indicator, {
              ["!important hidden"]: notifNspData?.seenState != "UNSEEN",
            })}
          />
        </div>

        <div className={css.notifWrap}>
          <div className={css.messageBlock}>
            <div className={css.messageCont}>
              <span className={css.username}>{notifNspData?.kind?.namespaceInvite?.requestedBy.globalName}</span>
              <span className={css.message}>invited you to collaborate in</span>
              <NamespaceTag
                icon={notifNspData?.kind?.namespaceInvite?.namespace.avatar!}
                namespaceName={notifNspData?.kind?.namespaceInvite?.namespace.globalName!}
                namespaceDisplayName={notifNspData?.kind?.namespaceInvite?.namespace.displayName!}
                iconSize={22}
              />
            </div>
          </div>

          <div className={css.time}>{dayjs(notifNspData?.createdAt).fromNow()}</div>
          {actionTaken == "waiting" && (
            <NotificationActionBlock
              accepting={acceptingNspInvit}
              rejecting={rejectingNspInvit}
              onAccept={handleOnAcceptInvit}
              onReject={handleOnRejectInvit}
            />
          )}
        </div>
      </div>
    </div>
  );
};

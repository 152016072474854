import React from "react";

import Bell from "@assets/icons/Bell";
import { NotificationMenu_fragment_User$key } from "@generated/NotificationMenu_fragment_User.graphql";
import { Menu } from "@headlessui/react";

import NotificationMenu from "./NotificationMenu";
import cssNotif from "./NotificationMenu/NotificationMenu.module.css";
import styles from "./index.module.css";

interface BellForNotificationsProps {
  fragmentRef: NotificationMenu_fragment_User$key;
}

const BellForNotifications: React.FC<BellForNotificationsProps> = ({ fragmentRef }) => {
  return (
    <Menu as="div" className="relative">
      <Menu.Button>
        <Bell data-labelicon="labelicon" className={cssNotif.labelicon} />
      </Menu.Button>
      <Menu.Items>
        <NotificationMenu notificationRef={fragmentRef} className={styles.NotificationMenu} />
      </Menu.Items>
    </Menu>
  );
};

export default BellForNotifications;

/**
 * @generated SignedSource<<f1fed258894e28257a2a6cfde6e2c90e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type UserNotificationSeenState = "SEEN" | "SEEN_AND_READ" | "UNSEEN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type NotificationMenuItem_UserNotification$data = {
  readonly body: {
    readonly ranges: ReadonlyArray<{
      readonly entity: {
        readonly avatar?: string;
        readonly id?: string;
        readonly " $fragmentSpreads": FragmentRefs<"RenderRangesEntity">;
      };
      readonly length: number;
      readonly offset: number;
    }>;
    readonly text: string;
    readonly " $fragmentSpreads": FragmentRefs<"RenderRanges_data">;
  };
  readonly createdAt: any;
  readonly icon: string | null | undefined;
  readonly id: string;
  readonly kind: {
    readonly __typename: "UserNotificationKindIncomingNamespaceInvite";
  } | {
    readonly __typename: "UserNotificationKindIncomingPackageInvite";
  } | {
    readonly __typename: "UserNotificationKindIncomingPackageTransfer";
  } | {
    readonly __typename: "UserNotificationKindValidateEmail";
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined;
  readonly seenState: UserNotificationSeenState;
  readonly " $fragmentSpreads": FragmentRefs<"NotificationItemInvitCollabNsp_UserNotificationKindIncomingNamespaceInvite" | "NotificationItemInvitCollabPkg_UserNotificationKindIncomingPackageInvite" | "NotificationItemReqTransfertPkg_UserNotificationKindIncomingPackageInvite" | "NotificationItemValidateEmail_UserNotificationKindValidateEmail">;
  readonly " $fragmentType": "NotificationMenuItem_UserNotification";
};
export type NotificationMenuItem_UserNotification$key = {
  readonly " $data"?: NotificationMenuItem_UserNotification$data;
  readonly " $fragmentSpreads": FragmentRefs<"NotificationMenuItem_UserNotification">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NotificationMenuItem_UserNotification",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "EventBody",
      "kind": "LinkedField",
      "name": "body",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "RenderRanges_data"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "NodeBodyRange",
          "kind": "LinkedField",
          "name": "ranges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "entity",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "RenderRangesEntity"
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "avatar",
                      "storageKey": null
                    }
                  ],
                  "type": "User",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "offset",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "length",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "icon",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "seenState",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NotificationItemInvitCollabNsp_UserNotificationKindIncomingNamespaceInvite"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NotificationItemInvitCollabPkg_UserNotificationKindIncomingPackageInvite"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NotificationItemReqTransfertPkg_UserNotificationKindIncomingPackageInvite"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NotificationItemValidateEmail_UserNotificationKindValidateEmail"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "kind",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": (v1/*: any*/),
          "type": "UserNotificationKindIncomingPackageTransfer",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v1/*: any*/),
          "type": "UserNotificationKindIncomingPackageInvite",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v1/*: any*/),
          "type": "UserNotificationKindIncomingNamespaceInvite",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v1/*: any*/),
          "type": "UserNotificationKindValidateEmail",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserNotification",
  "abstractKey": null
};
})();

(node as any).hash = "e6bddcb6cb93cd6483d2a072a0185181";

export default node;

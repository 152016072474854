/**
 * @generated SignedSource<<a757984e3ef144c48cb1d27afbf67243>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RenderRanges_data$data = {
  readonly ranges: ReadonlyArray<{
    readonly entity: {
      readonly " $fragmentSpreads": FragmentRefs<"RenderRangesEntity">;
    };
    readonly length: number;
    readonly offset: number;
  }>;
  readonly text: string;
  readonly " $fragmentType": "RenderRanges_data";
};
export type RenderRanges_data$key = {
  readonly " $data"?: RenderRanges_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"RenderRanges_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RenderRanges_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "NodeBodyRange",
      "kind": "LinkedField",
      "name": "ranges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "entity",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RenderRangesEntity"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "offset",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "length",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "EventBody",
  "abstractKey": null
};

(node as any).hash = "3d920487e3812339bec82403902c66d1";

export default node;

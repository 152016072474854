import React from "react";

const ChevronRightSettingIcon = (props: any) => {
  return (
    <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1.5L5 5.5L1 9.5" stroke={props.color} strokeWidth="1.5" />
    </svg>
  );
};

export default ChevronRightSettingIcon;

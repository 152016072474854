/**
 * @generated SignedSource<<5a72543301b904e04a4117ecb3c68b6e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type UserNotificationSeenState = "SEEN" | "SEEN_AND_READ" | "UNSEEN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type NotificationItemValidateEmail_UserNotificationKindValidateEmail$data = {
  readonly createdAt: any;
  readonly icon: string | null | undefined;
  readonly id: string;
  readonly kind: {
    readonly user?: {
      readonly avatar: string;
      readonly fullName: string;
      readonly id: string;
      readonly isEmailValidated: boolean;
      readonly username: string;
    };
  } | null | undefined;
  readonly seenState: UserNotificationSeenState;
  readonly " $fragmentType": "NotificationItemValidateEmail_UserNotificationKindValidateEmail";
};
export type NotificationItemValidateEmail_UserNotificationKindValidateEmail$key = {
  readonly " $data"?: NotificationItemValidateEmail_UserNotificationKindValidateEmail$data;
  readonly " $fragmentSpreads": FragmentRefs<"NotificationItemValidateEmail_UserNotificationKindValidateEmail">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NotificationItemValidateEmail_UserNotificationKindValidateEmail",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "icon",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "seenState",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "kind",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "user",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "username",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "fullName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "avatar",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isEmailValidated",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "UserNotificationKindValidateEmail",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserNotification",
  "abstractKey": null
};
})();

(node as any).hash = "02d7df54a895b666001c89c8d6da1757";

export default node;

import Cookies from "js-cookie";
import { GetStartedList, GuidesList } from "@layout/Header";
import Image from "next/image";
import Link from "next/link";
import React, { Fragment, useEffect, useState } from "react";

// @ts-ignore
import leftArrow from "@assets/icons/leftArrow.svg?url";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { AuthenticatedNavbarUserAvatar_data$key } from "@generated/AuthenticatedNavbarUserAvatar_data.graphql";
import { useFragment } from "react-relay";
import { AuthenticatedNavbarUserAvatar_dataFragment } from "./AuthenticatedNavbarUserAvatar";

interface AuthenticatedMobileMenuProps {
  open: boolean;
  onClose: (closed: boolean) => void;
  searchRender: React.ReactNode;
  fragmentRef: AuthenticatedNavbarUserAvatar_data$key;
}

const AuthenticatedMobileMenu: React.FC<AuthenticatedMobileMenuProps> = ({
  open,
  onClose,
  searchRender,
  fragmentRef,
}) => {
  const data = useFragment(AuthenticatedNavbarUserAvatar_dataFragment, fragmentRef);

  const [isMenuOpen, setMenuOpen] = useState<null | "publish">(null);

  const removeTOSFromStorage = () => {
    localStorage.removeItem("show_tos_modal_" + data.username);
  };

  useEffect(() => {
    if (!open) {
      setMenuOpen(null);
    }
  }, [open]);

  const handleLogout = () => {
    Cookies.remove("auth_token");
    Cookies.remove("username");
    removeTOSFromStorage();
    window.location.href = "/";
  };

  const DeveloperSubMenu = () => (
    <Menu>
      <Menu.Item as={Fragment}>
        <div>
          <div>
            <div className="mb-4 flex items-center border-b border-[#E5E5E8] pb-4 font-semibold lg:hidden">
              <Image
                src={leftArrow}
                alt="arrow"
                width={6}
                height={11}
                className="mr-2 rotate-180 cursor-pointer"
                onClick={() => setMenuOpen(null)}
              />{" "}
              Developers
            </div>
            <div className="lg:flex">
              <div className="w-[130px]">
                <p className="text-dark-gray whitespace-nowrap pb-3 font-medium">Learn more</p>
                {GetStartedList?.map((document, index) => (
                  <Link href={document.href} className="block pb-3 font-semibold" key={index}>
                    {document.title}
                  </Link>
                ))}
              </div>
              <div>
                <p className="text-dark-gray font-medium">Get Started</p>
                {GuidesList?.map((guides, index) => (
                  <Link href={guides?.href} key={index} className="block pt-3 font-semibold">
                    {guides?.guide}
                  </Link>
                ))}
              </div>
            </div>
          </div>
          {/* {isLogin && <SignoutRender />} */}
        </div>
      </Menu.Item>
    </Menu>
  );

  // const SignoutRender = () => (
  //   <div className="mt-4 flex items-center justify-between border-t border-[#E5E5E8] pt-5">
  //     <div className="flex items-center">
  //       <Image src={profile} alt="profile" />
  //       <p className="text-dark-gray ml-3 text-new-base font-medium">Julian</p>
  //     </div>
  //     <Button classname="font-zeitung ml-2" title="Sign out" onClick={logout} />
  //   </div>
  // );

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed left-4 right-4 top-14 z-[99] overflow-hidden" onClose={onClose}>
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="" />
          </Transition.Child>
          <div className="fixed left-4 right-4 top-14 flex max-w-full">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-300 sm:duration-300"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-300 sm:duration-300"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="mt-2 flex h-full w-full flex-col overflow-y-auto rounded-xl border border-[#cecdd2] bg-[#FBFBFC] shadow-xl">
                <div className="p-6">
                  {isMenuOpen == "publish" ? (
                    <DeveloperSubMenu />
                  ) : (
                    <div className="w-full">
                      <div className="mt-5 w-full pb-10">{searchRender}</div>
                      <Link
                        href="/"
                        className="block pb-3 text-new-base font-semibold lg:mr-6 lg:flex lg:pb-0 lg:text-[#7f7b87]"
                      >
                        Dashboard
                      </Link>
                      {/* <Link
                        href="/explore"
                        className="block pb-3 text-new-base font-semibold lg:mr-6 lg:flex lg:pb-0 lg:text-[#7f7b87]"
                      >
                        Explore packages
                      </Link> */}
                      <div className="relative">
                        <Menu>
                          <Menu.Button
                            className="flex w-full items-center justify-between pb-3 text-new-base font-semibold lg:mr-6 lg:pb-0 lg:text-[#7f7b87]"
                            onClick={() => setMenuOpen("publish")}
                          >
                            Developers <Image src={leftArrow} alt="arrow" width={6} height={11} className="lg:hidden" />
                          </Menu.Button>
                        </Menu>
                      </div>

                      <Link
                        href="/posts"
                        className="block pb-3 text-new-base font-semibold lg:mr-6 lg:flex lg:pb-0 lg:text-[#7f7b87]"
                      >
                        Blog
                      </Link>
                      <Link
                        href="/templates"
                        className="block pb-3 text-new-base font-semibold lg:mr-6 lg:flex lg:pb-0 lg:text-[#7f7b87]"
                      >
                        Templates
                      </Link>
                      <button
                        onClick={handleLogout}
                        className="flex w-full items-center border-t border-[#E5E5E8] py-5 text-new-base font-semibold"
                      >
                        Sign out
                      </button>
                    </div>
                  )}
                </div>
              </div>
              {/* <ProductSubMenu /> */}
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default AuthenticatedMobileMenu;

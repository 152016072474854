import React from "react";

import { cn } from "@libs/helper";

const Loading2 = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cn("", props?.className)}
    >
      <path
        d="M.771 8.252a7.233 7.233 0 1114.458-.504A7.233 7.233 0 01.77 8.252h0z"
        stroke="#0F0518"
        strokeOpacity={0.2}
        strokeWidth={1.548}
      />
      <mask
        id="prefix__a"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={9}
        y={0}
        width={6}
        height={5}
      >
        <path d="M10.61 1.236a7.25 7.25 0 013.477 2.826" stroke="#2F80ED" strokeWidth={1.5} strokeLinecap="round" />
      </mask>
      <g mask="url(#prefix__a)">
        <circle cx={8} cy={8} r={8} fill="url(#prefix__paint0_angular_1943_8405)" />
      </g>
      <defs>
        <radialGradient
          id="prefix__paint0_angular_1943_8405"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(0 8 -8 0 8 8)"
        >
          <stop offset={0.5} stopColor="#0FF" />
          <stop offset={0.682} stopColor="#001AFF" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default Loading2;

import ChevronRightSettingIcon from "@components/ChevronRightSettingIcon";
import DialogModal from "@components/shared/elements/dialog-modal";
import React, { useRef } from "react";

import { Dialog } from "@headlessui/react";
import { CloseModalIcon, MessageIcon } from "@icons";

type IEmailSentProp = {
  onClose: any;
  open: boolean;
};

const EmailSentModal = ({ onClose, open }: IEmailSentProp) => {
  const refDiv = useRef(null);

  return (
    <>
      <DialogModal open={open} onClose={onClose}>
        <div
          id="email-sent-modal"
          className="relative my-[12px] inline-block w-[288px] rounded bg-white p-[32px] text-left align-middle shadow-card transition-all"
        >
          <span className="absolute top-[12px] right-[12px] z-10 cursor-pointer" onClick={onClose} ref={refDiv}>
            <CloseModalIcon />
          </span>
          <MessageIcon className="mb-[24px] h-[32px] w-[38px]" />
          <Dialog.Title as="h3" className="text-xl font-bold leading-7 text-darkpurple">
            We've sent you <br />
            an email
          </Dialog.Title>

          <div className="mt-[8px]">
            <p className="text-xs leading-4 text-darkpurple-60">
              Follow the instruction to
              <br /> confirm your Email
            </p>
          </div>

          <button
            type="button"
            id="start-using-wapm-btn"
            className="mt-[24px] inline-flex h-[32px] flex-wrap items-center justify-between gap-[8px] rounded-sm bg-purple px-[12px] py-[6px] text-sm font-semibold leading-4 text-white disabled:bg-grey-50"
            onClick={onClose}
          >
            <ChevronRightSettingIcon color="#fff" />
            <p className="text-md">Start Using Wasmer</p>
          </button>
        </div>
      </DialogModal>
    </>
  );
};

export default EmailSentModal;

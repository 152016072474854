import React, { ReactNode } from "react";
import css from "./PackageTag.module.css";

import clsx from "clsx";
import PackageNameExt from "@components/AppDetailPage/AppDetailSidebar/PackageNameExt";
import Image from "next/image";

export type linkingType = "package" | "owner-package" | "nolink";
interface PackageTagInterface {
  icon: string;
  packageName: string;
  owner: string;
  label?: ReactNode;
  url?: string;
  iconSize?: number;
  linking?: linkingType;
  className?: string;
}
const PackageTag = ({ icon, packageName, owner, linking, iconSize = 32, className = "" }: PackageTagInterface) => {
  return (
    <div className={clsx(css.tagCont, className)}>
      {/* {label && <div className={css.tagLabel} >{label}</div>} */}

      <div className={clsx(css.tagWrap, className)}>
        <Image src={icon} alt="avatar" width={iconSize} height={iconSize} className={css.icon} crossOrigin="anonymous" />

        <PackageNameExt displayName={{ owner: owner, packageName: packageName }} linking={linking} />
      </div>
    </div>
  );
};
export default PackageTag;

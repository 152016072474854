/**
 * @generated SignedSource<<7d7276093ccefd7652a147468d177df9>>
 * @relayHash 60dc906bea4734e4210ae60d039f3fc5
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 60dc906bea4734e4210ae60d039f3fc5

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RequestValidationEmailInput = {
  clientMutationId?: string | null | undefined;
  userId?: string | null | undefined;
};
export type NotificationItemValidateEmail_ReqEmailValidationMutation$variables = {
  input: RequestValidationEmailInput;
};
export type NotificationItemValidateEmail_ReqEmailValidationMutation$data = {
  readonly requestValidationEmail: {
    readonly success: boolean;
    readonly user: {
      readonly email: string;
      readonly id: string;
      readonly isEmailValidated: boolean;
      readonly username: string;
    } | null | undefined;
  } | null | undefined;
};
export type NotificationItemValidateEmail_ReqEmailValidationMutation = {
  response: NotificationItemValidateEmail_ReqEmailValidationMutation$data;
  variables: NotificationItemValidateEmail_ReqEmailValidationMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RequestValidationEmailPayload",
    "kind": "LinkedField",
    "name": "requestValidationEmail",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isEmailValidated",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationItemValidateEmail_ReqEmailValidationMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NotificationItemValidateEmail_ReqEmailValidationMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "60dc906bea4734e4210ae60d039f3fc5",
    "metadata": {},
    "name": "NotificationItemValidateEmail_ReqEmailValidationMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "b885a24de87c015a67a82f1ca2c6ef90";

export default node;

import React from "react";

interface NotifIndicatorIconInterface {
  className?: string;
}

const NotifIndicatorIcon = ({ className = "" }: NotifIndicatorIconInterface) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="45 21 16 16"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.4" filter="url(#filter0_dddd_6066_142269)">
        <circle cx="52" cy="28" r="6" fill="url(#paint0_radial_6066_142269)" />
      </g>
      <g filter="url(#filter1_di_6066_142269)">
        <circle cx="52" cy="28" r="4" fill="url(#paint1_radial_6066_142269)" shapeRendering="crispEdges" />
      </g>
      <defs>
        <filter
          id="filter0_dddd_6066_142269"
          x="-2.61353"
          y="-13.3553"
          width="109.227"
          height="109.227"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.600439" />
          <feGaussianBlur stdDeviation="1.1008" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.137255 0 0 0 0 0.0627451 0 0 0 0 0.266667 0 0 0 0.0274 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6066_142269" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.66014" />
          <feGaussianBlur stdDeviation="3.04358" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.137255 0 0 0 0 0.0627451 0 0 0 0 0.266667 0 0 0 0.035 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_6066_142269" result="effect2_dropShadow_6066_142269" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.99697" />
          <feGaussianBlur stdDeviation="7.32778" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.137255 0 0 0 0 0.0627451 0 0 0 0 0.266667 0 0 0 0.0426 0" />
          <feBlend mode="normal" in2="effect2_dropShadow_6066_142269" result="effect3_dropShadow_6066_142269" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="13.2582" />
          <feGaussianBlur stdDeviation="24.3068" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.137255 0 0 0 0 0.0627451 0 0 0 0 0.266667 0 0 0 0.05 0" />
          <feBlend mode="normal" in2="effect3_dropShadow_6066_142269" result="effect4_dropShadow_6066_142269" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect4_dropShadow_6066_142269" result="shape" />
        </filter>
        <filter
          id="filter1_di_6066_142269"
          x="42.5607"
          y="18.5607"
          width="18.8786"
          height="18.8786"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2.71964" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.376471 0 0 0 0 0.364706 0 0 0 0 0.913725 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6066_142269" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6066_142269" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.43871" />
          <feGaussianBlur stdDeviation="0.719356" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
          <feBlend mode="soft-light" in2="shape" result="effect2_innerShadow_6066_142269" />
        </filter>
        <radialGradient
          id="paint0_radial_6066_142269"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(41.4 20.4907) rotate(41.6559) scale(18.6057 18.4979)"
        >
          <stop stopColor="#FBFBFC" />
          <stop offset="1" stopColor="#FBFBFC" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_6066_142269"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(55.3247 24.4734) rotate(118.157) scale(8.30502 7.98358)"
        >
          <stop stopColor="#605DE9" />
          <stop offset="1" stopColor="#605DE9" stopOpacity="0.8" />
        </radialGradient>
      </defs>
    </svg>
  );
};
export default NotifIndicatorIcon;

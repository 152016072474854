import clsx from "clsx";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import Image from "next/image";
import React, { useCallback } from "react";
import { graphql, useFragment, useMutation } from "react-relay";

import NotifIndicatorIcon from "@assets/icons/NotifIndicatorIcon";
import { ToasterAlertDefault, ToasterAlertError } from "@components/shared/ToasterAlert";
import { NotificationItemValidateEmail_ReqEmailValidationMutation } from "@generated/NotificationItemValidateEmail_ReqEmailValidationMutation.graphql";
import { NotificationItemValidateEmail_UserNotificationKindValidateEmail$key } from "@generated/NotificationItemValidateEmail_UserNotificationKindValidateEmail.graphql";

import NotificationActionBlock from "../NotificationActionBlock";
import css from "./NotificationItemValidateEmail.module.css";

dayjs.extend(relativeTime);

const notifValidateEmail_Fragment = graphql`
  fragment NotificationItemValidateEmail_UserNotificationKindValidateEmail on UserNotification {
    id
    icon
    seenState
    createdAt
    kind {
      ... on UserNotificationKindValidateEmail {
        user {
          id
          username
          fullName
          avatar
          isEmailValidated
        }
      }
    }
  }
`;

interface NotificationItemValidateEmailInterface {
  notifRef: (NotificationItemValidateEmail_UserNotificationKindValidateEmail$key & { body?: { text?: string } }) | null;
}

const NotificationItemValidateEmail = ({ notifRef }: NotificationItemValidateEmailInterface) => {
  const notifData = useFragment(notifValidateEmail_Fragment, notifRef);

  const [requestEmailValidation, requestingEmailValidation] =
    useMutation<NotificationItemValidateEmail_ReqEmailValidationMutation>(graphql`
      mutation NotificationItemValidateEmail_ReqEmailValidationMutation($input: RequestValidationEmailInput!) {
        requestValidationEmail(input: $input) {
          success
          user {
            id
            username
            email
            isEmailValidated
          }
        }
      }
    `);

  const handleResendEmail = useCallback(() => {
    if (requestingEmailValidation) return;
    requestEmailValidation({
      variables: {
        input: {
          userId: notifData?.kind?.user?.id!,
        },
      },
      onCompleted: (_response, errors) => {
        if (errors && errors.length) {
          ToasterAlertError({ message: errors[0].message });
        } else {
          ToasterAlertDefault({ message: "Your request to validate your email was sent " });
        }
      },
      onError: (error) => {
        console.error(error);
        ToasterAlertError({
          message: `An error while requesting the confirmation of your email, please try again later`,
        });
      },
    });
  }, [requestEmailValidation, notifData?.kind?.user]);

  return (
    <div className={css.wrapper}>
      <div className={css.content}>
        <div className={css.iconWrap}>
          {notifData?.kind?.user?.avatar! && (
            <Image
              className={css.icon}
              src={notifData?.kind?.user.avatar!}
              width={32}
              height={32}
              alt={notifData.kind.user.fullName || "user"}
            />
          )}
          <NotifIndicatorIcon
            className={clsx(css.indicator, {
              ["!important hidden"]: notifData?.seenState != "UNSEEN",
            })}
          />
        </div>

        <div className={css.notifWrap}>
          <div className={css.messageBlock}>
            <div className={css.messageCont}>
              <span className={css.message}>{notifRef?.body?.text}</span>
            </div>
          </div>

          <div className={css.time}>{dayjs(notifData?.createdAt).fromNow()}</div>
          {!notifData?.kind?.user?.isEmailValidated && (
            <NotificationActionBlock
              showReject={false}
              accepting={requestingEmailValidation}
              onAccept={handleResendEmail}
              acceptLabel="Resend"
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default NotificationItemValidateEmail;

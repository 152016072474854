/**
 * @generated SignedSource<<2dfb3e9c57ece70689e67153c6a5da7f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type UserNotificationSeenState = "SEEN" | "SEEN_AND_READ" | "UNSEEN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type NotificationItemReqTransfertPkg_UserNotificationKindIncomingPackageInvite$data = {
  readonly createdAt: any;
  readonly icon: string | null | undefined;
  readonly id: string;
  readonly kind: {
    readonly packageTransferRequest?: {
      readonly approvedBy: {
        readonly avatar: string;
        readonly globalName: string;
      } | null | undefined;
      readonly createdAt: any;
      readonly declinedBy: {
        readonly avatar: string;
        readonly globalName: string;
      } | null | undefined;
      readonly id: string;
      readonly package: {
        readonly icon: string;
        readonly owner: {
          readonly globalName: string;
        };
        readonly packageName: string;
      };
      readonly requestedBy: {
        readonly avatar: string;
        readonly globalName: string;
      };
    };
  } | null | undefined;
  readonly seenState: UserNotificationSeenState;
  readonly " $fragmentType": "NotificationItemReqTransfertPkg_UserNotificationKindIncomingPackageInvite";
};
export type NotificationItemReqTransfertPkg_UserNotificationKindIncomingPackageInvite$key = {
  readonly " $data"?: NotificationItemReqTransfertPkg_UserNotificationKindIncomingPackageInvite$data;
  readonly " $fragmentSpreads": FragmentRefs<"NotificationItemReqTransfertPkg_UserNotificationKindIncomingPackageInvite">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "icon",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "globalName",
  "storageKey": null
},
v4 = [
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "avatar",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NotificationItemReqTransfertPkg_UserNotificationKindIncomingPackageInvite",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "seenState",
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "kind",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PackageTransferRequest",
              "kind": "LinkedField",
              "name": "packageTransferRequest",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "declinedBy",
                  "plural": false,
                  "selections": (v4/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "approvedBy",
                  "plural": false,
                  "selections": (v4/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "requestedBy",
                  "plural": false,
                  "selections": (v4/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Package",
                  "kind": "LinkedField",
                  "name": "package",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "owner",
                      "plural": false,
                      "selections": [
                        (v3/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "packageName",
                      "storageKey": null
                    },
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "UserNotificationKindIncomingPackageTransfer",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserNotification",
  "abstractKey": null
};
})();

(node as any).hash = "dceeabe69afdd9af1718e83a9ce6639e";

export default node;

import clsx from "clsx";
import React from "react";
import { twMerge } from "tailwind-merge";

const World = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={twMerge(clsx("stroke-[#0B7046]", props?.className))}
    >
      <path
        d="M8 15.195c-2.057-1.543-3.086-4.629-3.086-7.2 0-2.572 1.029-5.657 3.086-7.2M8.002 15.195c2.057-1.543 3.086-4.629 3.086-7.2 0-2.572-1.029-5.657-3.086-7.2M1.6 8h13.013"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 15.195a7.2 7.2 0 100-14.4 7.2 7.2 0 000 14.4z"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default World;

import Link from "next/link";
import React, { useCallback } from "react";
import { graphql, useFragment, useMutation } from "react-relay";

import { RenderRangesEntity$key } from "@generated/RenderRangesEntity.graphql";
import { RenderRanges_data$key } from "@generated/RenderRanges_data.graphql";
import { DownloadPackage, EyeIcon, HeartOutlinedIcon } from "@icons";

interface ExternalLinkProps {
  href: string;
  children: React.ReactNode;
}
const ExternalLink: React.FC<ExternalLinkProps> = ({ href, children }) => {
  return (
    <Link href={href}>
      <span className="text-[13px] font-[600] text-[#605DE9] hover:underline">{children}</span>
    </Link>
  );
};
const OwnerName: React.FC<ExternalLinkProps> = ({ href, children }) => {
  return (
    <Link href={href}>
      <span className="text-[13px] font-[600] text-[#0F0518]">{children}</span>
    </Link>
  );
};

export type NodeItem<T> = {
  readonly offset: number;
  readonly length: number;
  readonly entity: T;
};
type NodeRange<T> = readonly NodeItem<T>[];
const intoPairs = (xs: any[]) => xs.slice(1).map((x, i) => [xs[i], x]);
const breakAt = (places: string[], str: string) =>
  intoPairs([0, ...places, str.length]).map(([a, b]) => str.substring(a, b));
export const breakWhere = (words: NodeRange<any>, str: string) =>
  breakAt(
    //@ts-ignore
    words.reduce(
      //@ts-ignore
      (a, { offset, length }) => [...a, offset, offset + length],
      []
    ),
    str
  );

const RenderEntity = (s: string, baseEntity?: RenderRangesEntity$key, card?: boolean) => {
  const [commitLike, _isInFlightLike] = useMutation(graphql`
    mutation RenderRangesLikeMutation($input: LikePackageInput!) {
      likePackage(input: $input) {
        package {
          id
          likersCount
          viewerHasLiked
        }
      }
    }
  `);
  const [commitUnlike, _isInFlightUnlike] = useMutation(graphql`
    mutation RenderRangesUnlikeMutation($input: UnlikePackageInput!) {
      unlikePackage(input: $input) {
        package {
          id
          likersCount
          viewerHasLiked
        }
      }
    }
  `);
  const [commitWatch, _isInFlightWatch] = useMutation(graphql`
    mutation RenderRangesWatchMutation($input: WatchPackageInput!) {
      watchPackage(input: $input) {
        package {
          id
          watchersCount
          viewerIsWatching
        }
      }
    }
  `);
  const [commitUnwatch, _isInFlightUnwatch] = useMutation(graphql`
    mutation RenderRangesUnwatchMutation($input: UnwatchPackageInput!) {
      unwatchPackage(input: $input) {
        package {
          id
          watchersCount
          viewerIsWatching
        }
      }
    }
  `);

  const likePackage = useCallback(
    (id: string) => {
      commitLike({
        variables: {
          input: {
            packageId: id,
          },
        },
        optimisticUpdater: (store) => {
          const record = store.get(id);
          if (!record) return;
          const currentLikeCount = (record.getValue("likersCount") as number) || 0;
          const viewerHasLiked = record.getValue("viewerHasLiked") || false;

          if (!viewerHasLiked) {
            record.setValue(currentLikeCount + 1, "likersCount");
            record.setValue(true, "viewerHasLiked");
          }
        },
      });
    },
    [commitLike]
  );

  const unlikePackage = useCallback(
    (id: string) => {
      commitUnlike({
        variables: {
          input: {
            packageId: id,
          },
        },
        optimisticUpdater: (store) => {
          const record = store.get(id);
          if (!record) return;
          const currentLikeCount = (record.getValue("likersCount") as number) || 0;
          const viewerHasLiked = record.getValue("viewerHasLiked") || false;

          if (viewerHasLiked) {
            record.setValue(currentLikeCount - 1, "likersCount");
            record.setValue(false, "viewerHasLiked");
          }
        },
      });
    },
    [commitUnlike]
  );

  const watchPackage = useCallback(
    (id: string) => {
      commitWatch({
        variables: {
          input: {
            packageId: id,
          },
        },
      });
    },
    [commitWatch]
  );
  const unwatchPackage = useCallback(
    (id: string) => {
      commitUnwatch({
        variables: {
          input: {
            packageId: id,
          },
        },
      });
    },
    [commitUnwatch]
  );

  const entity = useFragment(
    graphql`
      fragment RenderRangesEntity on Node {
        __typename
        id
        ... on User {
          username
        }
        ... on Package {
          id
          name
          likersCount
          downloadsCount

          owner {
            globalName
          }

          watchersCount
          viewerHasLiked
          viewerIsWatching
        }
        ... on PackageVersion {
          id
          version
          package {
            id
            icon
            name
            packageName

            owner {
              globalName
            }

            likersCount
            downloadsCount
            watchersCount
            viewerHasLiked
            viewerIsWatching
          }
        }
        ... on Namespace {
          globalName
        }
        ... on BlogPost {
          slug
        }
        ... on DeployApp {
          name
          owner {
            globalName
          }
        }
      }
    `,
    baseEntity || null
  );
  if (!entity) {
    return <>{s}</>;
  }
  // For links, we do *not* want to use globalName for any entity other than the owner.
  // This is because globalName will often contain a formatted name, such as Wasmer (Official) (not a real one).
  if (entity.__typename == "User") {
    // We want this to hit /[owner]
    return <OwnerName href={`/${entity.username}`}>{s}</OwnerName>;
  } else if (entity.__typename == "Package") {
    return (
      <>
        {/* We want this to hit /[owner]/[packageName] */}
        <ExternalLink href={`/${entity.owner?.globalName}/${entity.name}`}>{s}</ExternalLink>

        {card && (
          <span className="mt-[56px] flex cursor-pointer items-center gap-[16px]">
            <span className="flex items-center gap-[6px] leading-none">
              <DownloadPackage />
              {(entity?.downloadsCount as number) < 1000 && <span className="text-xs font-bold"> {"< 1k"}</span>}
            </span>
            {entity?.viewerHasLiked ? (
              <span color="red" onClick={() => unlikePackage(entity?.id as string)} className="flex items-center">
                <HeartOutlinedIcon strokeWidth="2px" fill="#231044" />
                {(entity?.likersCount as number) > 0 && (
                  <span className="ml-[8px] text-xs font-bold">{entity.likersCount}</span>
                )}
              </span>
            ) : (
              <span onClick={() => likePackage(entity?.id as string)} className="flex items-center">
                <HeartOutlinedIcon strokeWidth="2px" />
                {(entity?.likersCount as number) > 0 && (
                  <span className="ml-[8px] text-xs font-bold">{entity?.likersCount}</span>
                )}
              </span>
            )}
            {entity?.viewerIsWatching ? (
              <span color="red" onClick={() => unwatchPackage(entity?.id as string)} className="flex items-center">
                <EyeIcon strokeWidth="2px" />
                {(entity?.watchersCount as number) > 0 && (
                  <span className="ml-[8px] text-xs font-bold">{entity?.watchersCount}</span>
                )}
              </span>
            ) : (
              <span onClick={() => watchPackage(entity?.id as string)} className="flex items-center">
                <EyeIcon strokeWidth="2px" />
                {(entity?.watchersCount as number) > 0 && (
                  <span className="ml-[8px] text-xs font-bold">{entity?.watchersCount}</span>
                )}
              </span>
            )}
          </span>
        )}
      </>
    );
  } else if (entity.__typename == "PackageVersion") {
    return (
      <>
        {/* We want this to hit /[owner]/[packageName@packageVersion] */}
        <ExternalLink href={`/${entity.package!.owner.globalName}/${entity.package?.packageName}@${entity.version}`}>
          {s}
        </ExternalLink>

        {card && (
          <span className="relative mt-[56px] flex cursor-pointer gap-[16px]">
            <span className="flex items-center gap-[6px] leading-none">
              <DownloadPackage strokeWidth="2" />
              {(entity.package?.downloadsCount as number) < 1000 && (
                <span className="text-xs font-bold"> {"< 1k"}</span>
              )}
            </span>
            {entity.package?.viewerHasLiked ? (
              <span
                color="red"
                onClick={() => unlikePackage(entity?.package?.id as string)}
                className="flex items-center"
              >
                <HeartOutlinedIcon strokeWidth="2px" fill="#231044" />
                {entity.package?.likersCount > 0 && (
                  <span className="ml-[8px] text-xs font-bold">{entity.package.likersCount}</span>
                )}
              </span>
            ) : (
              <span onClick={() => likePackage(entity?.package?.id as string)} className="flex items-center">
                <HeartOutlinedIcon strokeWidth="2px" />
                {(entity.package?.likersCount as number) > 0 && (
                  <span className="ml-[8px] text-xs font-bold">{entity.package?.likersCount}</span>
                )}
              </span>
            )}

            {entity.package?.viewerIsWatching ? (
              <span
                color="red"
                onClick={() => unwatchPackage(entity?.package?.id as string)}
                className="flex items-center"
              >
                <EyeIcon strokeWidth="2px" />
                {entity.package?.watchersCount > 0 && (
                  <span className="ml-[8px] text-xs font-bold">{entity.package?.watchersCount}</span>
                )}
              </span>
            ) : (
              <span onClick={() => watchPackage(entity?.package?.id as string)} className="flex items-center">
                <EyeIcon strokeWidth="2px" />
                {(entity.package?.watchersCount as number) > 0 && (
                  <span className="ml-[8px] text-xs font-bold">{entity.package?.watchersCount}</span>
                )}
              </span>
            )}
          </span>
        )}
      </>
    );
  } else if (entity.__typename == "Namespace") {
    // We want this to hit /[owner]/page.tsx
    // It's okay to use globalName here as owner.globalname correctly resolves to an unformatted lowercase one.
    return <OwnerName href={`/${entity.globalName}`}>{s}</OwnerName>;
  } else if (entity.__typename == "BlogPost") {
    // We want this to hit /posts/[slug]
    return <ExternalLink href={`/posts/${entity.slug}`}>{s}</ExternalLink>;
  } else if (entity.__typename == "DeployApp") {
    // We want this to hit /apps/[appOwner]/[appName]
    return <ExternalLink href={`/apps/${entity.owner!.globalName}/${entity.name}`}>{s}</ExternalLink>;
  }
  return <>{s}</>;
};

const RenderRanges_dataFragment = graphql`
  fragment RenderRanges_data on EventBody {
    text
    ranges {
      entity {
        ...RenderRangesEntity
      }
      offset
      length
    }
  }
`;

type RenderRangesProps = {
  fragmentRef: RenderRanges_data$key;
  maxCharsForText?: number;
  entityRender?: (text: string, entity?: any, card?: boolean) => JSX.Element;
  card?: boolean;
};

const RenderRanges: React.FC<RenderRangesProps> = ({
  fragmentRef,
  maxCharsForText,
  card,
  entityRender = RenderEntity,
}) => {
  const data = useFragment(RenderRanges_dataFragment, fragmentRef),
    text = maxCharsForText ? data?.text?.slice(0, maxCharsForText) + "..." : data?.text,
    ranges = data?.ranges;
  const sortedNodes = ranges.slice(0).sort(({ offset: o1 }, { offset: o2 }) => o1 - o2);

  return (
    <>
      {breakWhere(sortedNodes, text).map((s, i) => {
        if (i % 2 == 0) {
          return <React.Fragment key={i}>{entityRender(s)}</React.Fragment>;
        }
        const entity = sortedNodes[(i - 1) / 2].entity;
        return <React.Fragment key={i}>{entityRender(s, entity, card)}</React.Fragment>;
      })}
    </>
  );
};

export default RenderRanges;

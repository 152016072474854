import React from "react";
import { graphql, useFragment } from "react-relay";

import { NotificationMenuItem_UserNotification$key } from "@generated/NotificationMenuItem_UserNotification.graphql";

import NotificationItemInvitCollabNsp from "../NotificationItemInvitCollabNsp";
import NotificationItemInvitCollabPkg from "../NotificationItemInvitCollabPkg";
import NotificationItemReqTransfertPkg from "../NotificationItemReqTransfertPkg";
import NotificationItemValidateEmail from "../NotificationItemValidateEmail";
import RenderNotificationRanges from "../RenderNotificationRanges";

const NotificationMenuItem_Fragment = graphql`
  fragment NotificationMenuItem_UserNotification on UserNotification {
    id
    body {
      ...RenderRanges_data
      text
      ranges {
        entity {
          ...RenderRangesEntity
          ... on User {
            id
            avatar
          }
        }
        offset
        length
      }
    }
    icon
    seenState
    createdAt
    ...NotificationItemInvitCollabNsp_UserNotificationKindIncomingNamespaceInvite
    ...NotificationItemInvitCollabPkg_UserNotificationKindIncomingPackageInvite
    ...NotificationItemReqTransfertPkg_UserNotificationKindIncomingPackageInvite
    ...NotificationItemValidateEmail_UserNotificationKindValidateEmail
    kind {
      ... on UserNotificationKindIncomingPackageTransfer {
        __typename
      }
      ... on UserNotificationKindIncomingPackageInvite {
        __typename
      }
      ... on UserNotificationKindIncomingNamespaceInvite {
        __typename
      }
      ... on UserNotificationKindValidateEmail {
        __typename
      }
    }
  }
`;

interface NotificationMenuItemInterface {
  notifItemRef: NotificationMenuItem_UserNotification$key | null;
}

const NotificationMenuItem = ({ notifItemRef }: NotificationMenuItemInterface) => {
  const notifItemData = useFragment(NotificationMenuItem_Fragment, notifItemRef);

  if (notifItemData?.kind) {
    switch (notifItemData?.kind.__typename) {
      case "UserNotificationKindIncomingNamespaceInvite":
        return <NotificationItemInvitCollabNsp notifNspRef={notifItemData} />;
      case "UserNotificationKindIncomingPackageInvite":
        return <NotificationItemInvitCollabPkg notifPkgRef={notifItemData} />;
      case "UserNotificationKindIncomingPackageTransfer":
        return <NotificationItemReqTransfertPkg notifPkgRef={notifItemData} />;
      case "UserNotificationKindValidateEmail":
        return <NotificationItemValidateEmail notifRef={notifItemData} />;
      default:
        return null;
    }
  } else {
    return (
      <RenderNotificationRanges renderRangesFragmentRef={notifItemData?.body!} notifItemData={notifItemData!} />
      // <RenderRanges
      //     text={notifItemData?.body.text!}
      //     ranges={notifItemData?.body.ranges as any}
      // />
    );
  }
};
export default NotificationMenuItem;

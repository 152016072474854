import React, { Fragment, useRef } from "react";
import { useClickAway } from "react-use";

import { Dialog, Transition } from "@headlessui/react";

import css from "./dialog.module.css";

interface IDialogModalProps {
  onClose: (value: any) => void;
  open?: boolean;
  onSubmit?: (data: any) => void;
  children: React.ReactNode;
}

const DialogModal: React.FunctionComponent<IDialogModalProps> = ({ children, open, onClose }) => {
  const refDiv = useRef(null);

  useClickAway(refDiv, () => {
    onClose;
  });

  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog as="div" className={css.dialogModal} onClose={onClose} initialFocus={refDiv}>
          <div className="min-h-screen px-[4px] text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-300"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Overlay className={`fixed ${css.position}`} />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true" ref={refDiv}></span>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-300"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              {children}
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default DialogModal;

/**
 * @generated SignedSource<<dabc5a7b3bb8e3ecb47820a7e09e9d6f>>
 * @relayHash 4d1c4a42e26d219c0dad82c694a6e1c2
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 4d1c4a42e26d219c0dad82c694a6e1c2

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AcceptNamespaceCollaboratorInviteInput = {
  clientMutationId?: string | null | undefined;
  inviteId: string;
};
export type NotificationItemInvitCollabNsp_AcceptInvitMutation$variables = {
  input: AcceptNamespaceCollaboratorInviteInput;
};
export type NotificationItemInvitCollabNsp_AcceptInvitMutation$data = {
  readonly acceptNamespaceCollaboratorInvite: {
    readonly namespaceCollaboratorInvite: {
      readonly accepted: {
        readonly id: string;
      } | null | undefined;
      readonly id: string;
      readonly namespace: {
        readonly collaborators: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly user: {
                readonly id: string;
              };
            } | null | undefined;
          } | null | undefined>;
        };
      };
    };
  } | null | undefined;
};
export type NotificationItemInvitCollabNsp_AcceptInvitMutation = {
  response: NotificationItemInvitCollabNsp_AcceptInvitMutation$data;
  variables: NotificationItemInvitCollabNsp_AcceptInvitMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "NamespaceCollaborator",
  "kind": "LinkedField",
  "name": "accepted",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationItemInvitCollabNsp_AcceptInvitMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AcceptNamespaceCollaboratorInvitePayload",
        "kind": "LinkedField",
        "name": "acceptNamespaceCollaboratorInvite",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NamespaceCollaboratorInvite",
            "kind": "LinkedField",
            "name": "namespaceCollaboratorInvite",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Namespace",
                "kind": "LinkedField",
                "name": "namespace",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NamespaceCollaboratorConnection",
                    "kind": "LinkedField",
                    "name": "collaborators",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "NamespaceCollaboratorEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "NamespaceCollaborator",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NotificationItemInvitCollabNsp_AcceptInvitMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AcceptNamespaceCollaboratorInvitePayload",
        "kind": "LinkedField",
        "name": "acceptNamespaceCollaboratorInvite",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NamespaceCollaboratorInvite",
            "kind": "LinkedField",
            "name": "namespaceCollaboratorInvite",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Namespace",
                "kind": "LinkedField",
                "name": "namespace",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NamespaceCollaboratorConnection",
                    "kind": "LinkedField",
                    "name": "collaborators",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "NamespaceCollaboratorEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "NamespaceCollaborator",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "4d1c4a42e26d219c0dad82c694a6e1c2",
    "metadata": {},
    "name": "NotificationItemInvitCollabNsp_AcceptInvitMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "6dfbcebc55d5e1865e659be1b97b5f8a";

export default node;

"use client";

import React, { useEffect, useMemo } from "react";
import {
  ConnectionHandler,
  graphql,
  useMutation,
  usePaginationFragment,
  useRelayEnvironment,
  useSubscription,
} from "react-relay";
import { RecordSourceSelectorProxy } from "relay-runtime";

import NewLoading from "@components/NewLoading";
import Card from "@components/shared/Cards/Card";
import { NotificationMenuSubscription } from "@generated/NotificationMenuSubscription.graphql";
import { NotificationMenu_SeePendingNotificationsMutation } from "@generated/NotificationMenu_SeePendingNotificationsMutation.graphql";
import { NotificationMenu_fragment_User$key } from "@generated/NotificationMenu_fragment_User.graphql";
import { cn } from "@libs/helper";

import css from "./NotificationMenu.module.css";
import NotificationMenuItem from "./NotificationMenuItem";

const notificationsSubscription = graphql`
  subscription NotificationMenuSubscription($connections: [ID!]!, $userId: ID!) {
    userNotificationCreated(userId: $userId) {
      notificationDeletedId
      notification @prependNode(connections: $connections, edgeTypeName: "UserNotificationEdge") {
        ...NotificationMenuItem_UserNotification
      }
    }
  }
`;

const NotificationMenu_Fragment = graphql`
  fragment NotificationMenu_fragment_User on User
  @argumentDefinitions(firstNotifications: { type: "Int", defaultValue: 4 }, afterNotifications: { type: "String" })
  @refetchable(queryName: "NotificationMenu_Query") {
    id
    notifications(first: $firstNotifications, after: $afterNotifications)
      @connection(key: "NotificationMenu_notifications") {
      # __id
      hasPendingNotifications
      edges {
        node {
          id
          ...NotificationMenuItem_UserNotification
          seenState
        }
      }
    }
  }
`;

interface NotificationMenuInterface {
  notificationRef: NotificationMenu_fragment_User$key;
  className?: string;
}
const NotificationMenu: React.FC<NotificationMenuInterface> = ({ notificationRef, className }) => {
  const [setNotificationsAsSeen, _] = useMutation<NotificationMenu_SeePendingNotificationsMutation>(graphql`
    mutation NotificationMenu_SeePendingNotificationsMutation($input: SeePendingNotificationsInput!) {
      seePendingNotifications(input: $input) {
        success
      }
    }
  `);

  const { data: notifData, loadNext, hasNext } = usePaginationFragment(NotificationMenu_Fragment, notificationRef);

  const userId = notifData?.id!;

  const env = useRelayEnvironment();

  const notifications_connectionID = ConnectionHandler.getConnectionID(
    userId, // passed as input to the mutation/subscription
    "NotificationMenu_notifications"
  );
  const config = useMemo(
    () => ({
      variables: {
        // Pass the `connections` array
        connections: [notifications_connectionID],
        userId,
      },
      subscription: notificationsSubscription,
      updater: (store: RecordSourceSelectorProxy) => {
        const userNotificationCreated = store.getRootField("userNotificationCreated");
        const notificationDeletedId = userNotificationCreated?.getValue("notificationDeletedId");
        const newNotification = userNotificationCreated?.getLinkedRecord("notification");

        const viewerx = store.get(userId);
        if (viewerx) {
          const connectionRecord = ConnectionHandler.getConnection(viewerx, "NotificationMenu_notifications");
          if (connectionRecord) {
            if (notificationDeletedId) {
              ConnectionHandler.deleteNode(connectionRecord, notificationDeletedId as string);
              const edges = connectionRecord.getLinkedRecords("edges");
              const hasPendingNotifications = !!edges?.find((edge) => {
                return edge.getLinkedRecord("node")?.getValue("seenState") == "UNSEEN";
              });
              connectionRecord.setValue(hasPendingNotifications, "hasPendingNotifications");

              //             notifications &&
              // !!notifications.edges.find((edge) => {
              //   return edge?.node?.seenState == "UNSEEN";
              // });
            } else if (newNotification) {
              if (newNotification.getValue("seenState") == "UNSEEN") {
                connectionRecord.setValue(true, "hasPendingNotifications");
              }
            }
          }
        }
      },
    }),
    [env]
  );

  useSubscription<NotificationMenuSubscription>(config);

  useEffect(() => {
    setNotificationsAsSeen({
      variables: {
        input: {},
      },
      updater: (store) => {
        const viewerx = store.get(userId);
        if (viewerx && notifData?.notifications?.hasPendingNotifications) {
          const connectionRecord = ConnectionHandler.getConnection(viewerx, "HeaderNotificationMenu__notifications");
          if (connectionRecord) {
            const edges = connectionRecord.getLinkedRecords("edges");
            edges?.map((edge) => {
              const node = edge.getLinkedRecord("node");
              const seenState = node?.getValue("seenState");
              if (seenState == "UNSEEN") {
                node?.setValue("SEEN", "seenState");
              }
            });
            connectionRecord.setValue(false, "hasPendingNotifications");
          }
        }
      },
    });
  }, [notifData?.notifications?.hasPendingNotifications]);

  const noNotifications = notifData?.notifications?.edges?.length == 0,
    handleLoadMore = () => {
      loadNext(10);
    };

  return (
    <div className={css.cardWrapper}>
      <Card className={cn(className, css.wrapper)}>
        {!noNotifications ? (
          <>
            {notifData?.notifications.edges.map((edge) => {
              if (!edge || !edge.node) {
                return null;
              }

              return <NotificationMenuItem key={edge?.node?.id} notifItemRef={edge.node} />;
            })}
            {!hasNext ? null : (
              <div className="flex w-full items-center justify-center py-2">
                <NewLoading onVisible={handleLoadMore} />
              </div>
            )}
          </>
        ) : (
          <p className="px-[12px] py-[8px] text-sm text-grey-60">You don't have any notifications at the moment</p>
        )}
      </Card>
    </div>
  );
};
export default NotificationMenu;

import React from "react";

import { cn } from "@libs/helper";

const Loading0 = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cn("", props?.className)}
    >
      <path
        d="M15.25 8A7.25 7.25 0 11.751 8 7.25 7.25 0 0115.25 8h0z"
        stroke="#0F0518"
        strokeOpacity={0.2}
        strokeWidth={1.5}
      />
      <mask
        id="prefix__a"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={14}
        y={8}
        width={2}
        height={1}
      >
        <path d="M15.25 8v.046" stroke="#2F80ED" strokeWidth={1.5} />
      </mask>
      <g mask="url(#prefix__a)">
        <circle cx={8} cy={8} r={8} fill="url(#prefix__paint0_angular_1987_9525)" />
      </g>
      <defs>
        <radialGradient
          id="prefix__paint0_angular_1987_9525"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(0 8 -8 0 8 8)"
        >
          <stop stopColor="red" />
          <stop offset={0.156} stopColor="#FFE600" />
          <stop offset={0.328} stopColor="#05FF00" />
          <stop offset={0.5} stopColor="#0FF" />
          <stop offset={0.682} stopColor="#001AFF" />
          <stop offset={0.854} stopColor="#DB00FF" />
          <stop offset={1} stopColor="red" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default Loading0;

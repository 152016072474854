import Link from "next/link";
import React from "react";

import { cn } from "@libs/helper";

interface NavigationLinkProps extends React.HTMLAttributes<HTMLAnchorElement | HTMLDivElement> {
  active?: boolean;
  href?: string;
}

const NavigationLink: React.FC<NavigationLinkProps> = ({ children, className, href, active = false, ...restProps }) => {
  return !href ? (
    <div
      {...restProps}
      className={cn(
        "cursor-pointer font-gilroy text-[16px] font-[600] leading-normal tracking-[0.32px] text-[#0F0518] opacity-50 focus:opacity-100 hover:opacity-100 active:opacity-100",
        active && "opacity-100",
        className
      )}
    >
      {children}
    </div>
  ) : (
    <Link
      {...restProps}
      href={href}
      className={cn(
        "font-gilroy text-[16px] font-[600] leading-normal tracking-[0.32px] text-[#0F0518] opacity-50 focus:opacity-100 hover:opacity-100 active:opacity-100",
        active && "opacity-100",
        className
      )}
      passHref
    >
      {children}
    </Link>
  );
};

export default NavigationLink;

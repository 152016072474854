/**
 * @generated SignedSource<<0b2b8376d1f3c16834b454884c9f636c>>
 * @relayHash db16a542b75f2ccb07d2b1e8dc6312cd
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID db16a542b75f2ccb07d2b1e8dc6312cd

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type WatchPackageInput = {
  clientMutationId?: string | null | undefined;
  packageId: string;
};
export type RenderRangesWatchMutation$variables = {
  input: WatchPackageInput;
};
export type RenderRangesWatchMutation$data = {
  readonly watchPackage: {
    readonly package: {
      readonly id: string;
      readonly viewerIsWatching: boolean;
      readonly watchersCount: number;
    };
  } | null | undefined;
};
export type RenderRangesWatchMutation = {
  response: RenderRangesWatchMutation$data;
  variables: RenderRangesWatchMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "WatchPackagePayload",
    "kind": "LinkedField",
    "name": "watchPackage",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Package",
        "kind": "LinkedField",
        "name": "package",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "watchersCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "viewerIsWatching",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RenderRangesWatchMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RenderRangesWatchMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "db16a542b75f2ccb07d2b1e8dc6312cd",
    "metadata": {},
    "name": "RenderRangesWatchMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "fdc33c5237d8bf9936f4b564c03709b2";

export default node;

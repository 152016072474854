/**
 * @generated SignedSource<<fae2ef605a8b7714fe389a636b50bc83>>
 * @relayHash 248ad11bb9d851573d29a75e5f62f028
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 248ad11bb9d851573d29a75e5f62f028

import { ConcreteRequest, Query } from 'relay-runtime';
export type RenderNotificationRanges_viewerQuery$variables = Record<PropertyKey, never>;
export type RenderNotificationRanges_viewerQuery$data = {
  readonly viewer: {
    readonly avatar: string;
    readonly id: string;
  } | null | undefined;
};
export type RenderNotificationRanges_viewerQuery = {
  response: RenderNotificationRanges_viewerQuery$data;
  variables: RenderNotificationRanges_viewerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "avatar",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RenderNotificationRanges_viewerQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "RenderNotificationRanges_viewerQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": "248ad11bb9d851573d29a75e5f62f028",
    "metadata": {},
    "name": "RenderNotificationRanges_viewerQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "0c58ad0c5aed8914337bae54d5da6689";

export default node;

import React from "react";

import { cn } from "@libs/helper";

const WasmerBrandingLogo = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 29 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cn("", props?.className)}
    >
      <g clipPath="url(#prefix__clip0_1268_12249)" fill="#0F0518">
        <path d="M8.908 13.95v.1c0 1.187-.746 1.704-1.662 1.157-.917-.546-1.662-1.952-1.662-3.138v-.1L0 8.636v18.719l14.5 8.645V17.28L8.908 13.95z" />
        <path d="M16.158 9.629v.101c0 1.186-.746 1.704-1.662 1.157-.917-.547-1.662-1.952-1.662-3.138v-.101L7.25 4.32v6.697l8.88 5.296v12.023l5.62 3.352V12.97l-5.592-3.34z" />
        <path d="M23.408 5.313v.101c0 1.187-.746 1.704-1.662 1.157-.916-.547-1.662-1.952-1.662-3.138v-.1L14.5 0v6.697l8.88 5.296v12.023L29 27.369V8.649l-5.592-3.336z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0_1268_12249">
          <path fill="#fff" d="M0 0h29v36H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default WasmerBrandingLogo;

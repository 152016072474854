/**
 * @generated SignedSource<<d48f174b29de903431046c0616fb78e7>>
 * @relayHash d6df750645c8dd5b788f9cb0f352895e
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID d6df750645c8dd5b788f9cb0f352895e

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RemovePackageTransferRequestInput = {
  clientMutationId?: string | null | undefined;
  packageTransferRequestId: string;
};
export type NotificationItemReqTransfertPkg_RejectTransferMutation$variables = {
  input: RemovePackageTransferRequestInput;
};
export type NotificationItemReqTransfertPkg_RejectTransferMutation$data = {
  readonly removePackageTransferRequest: {
    readonly package: {
      readonly id: string;
      readonly isTransferring: boolean;
      readonly name: string;
      readonly namespace: string;
      readonly pendingInvites: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly declinedBy: {
              readonly username: string;
            } | null | undefined;
            readonly id: string;
          } | null | undefined;
        } | null | undefined>;
      };
    };
  } | null | undefined;
};
export type NotificationItemReqTransfertPkg_RejectTransferMutation = {
  response: NotificationItemReqTransfertPkg_RejectTransferMutation$data;
  variables: NotificationItemReqTransfertPkg_RejectTransferMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "namespace",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isTransferring",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationItemReqTransfertPkg_RejectTransferMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemovePackageTransferRequestPayload",
        "kind": "LinkedField",
        "name": "removePackageTransferRequest",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Package",
            "kind": "LinkedField",
            "name": "package",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PackageCollaboratorInviteConnection",
                "kind": "LinkedField",
                "name": "pendingInvites",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PackageCollaboratorInviteEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PackageCollaboratorInvite",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "declinedBy",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NotificationItemReqTransfertPkg_RejectTransferMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemovePackageTransferRequestPayload",
        "kind": "LinkedField",
        "name": "removePackageTransferRequest",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Package",
            "kind": "LinkedField",
            "name": "package",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PackageCollaboratorInviteConnection",
                "kind": "LinkedField",
                "name": "pendingInvites",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PackageCollaboratorInviteEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PackageCollaboratorInvite",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "declinedBy",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "d6df750645c8dd5b788f9cb0f352895e",
    "metadata": {},
    "name": "NotificationItemReqTransfertPkg_RejectTransferMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "5ab57bcc1cd40dfdf829b7960c791e32";

export default node;

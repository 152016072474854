/**
 * @generated SignedSource<<11092f40359e28684db0f5059449cb72>>
 * @relayHash f9110d72b448cf6fd8c10777938d75b3
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID f9110d72b448cf6fd8c10777938d75b3

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RemoveNamespaceCollaboratorInviteInput = {
  clientMutationId?: string | null | undefined;
  inviteId: string;
};
export type NotificationItemInvitCollabNsp_RejectInvitMutation$variables = {
  input: RemoveNamespaceCollaboratorInviteInput;
};
export type NotificationItemInvitCollabNsp_RejectInvitMutation$data = {
  readonly removeNamespaceCollaboratorInvite: {
    readonly namespace: {
      readonly pendingInvites: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly user: {
              readonly id: string;
            } | null | undefined;
          } | null | undefined;
        } | null | undefined>;
      };
    };
  } | null | undefined;
};
export type NotificationItemInvitCollabNsp_RejectInvitMutation = {
  response: NotificationItemInvitCollabNsp_RejectInvitMutation$data;
  variables: NotificationItemInvitCollabNsp_RejectInvitMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationItemInvitCollabNsp_RejectInvitMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemoveNamespaceCollaboratorInvitePayload",
        "kind": "LinkedField",
        "name": "removeNamespaceCollaboratorInvite",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Namespace",
            "kind": "LinkedField",
            "name": "namespace",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NamespaceCollaboratorInviteConnection",
                "kind": "LinkedField",
                "name": "pendingInvites",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NamespaceCollaboratorInviteEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "NamespaceCollaboratorInvite",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NotificationItemInvitCollabNsp_RejectInvitMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemoveNamespaceCollaboratorInvitePayload",
        "kind": "LinkedField",
        "name": "removeNamespaceCollaboratorInvite",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Namespace",
            "kind": "LinkedField",
            "name": "namespace",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NamespaceCollaboratorInviteConnection",
                "kind": "LinkedField",
                "name": "pendingInvites",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NamespaceCollaboratorInviteEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "NamespaceCollaboratorInvite",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "f9110d72b448cf6fd8c10777938d75b3",
    "metadata": {},
    "name": "NotificationItemInvitCollabNsp_RejectInvitMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "a4f62c303f8c12e5758b0092661401a7";

export default node;

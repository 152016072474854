/**
 * @generated SignedSource<<84fa18429763babb3f53fa7500a123ef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AuthenticatedNavbar_data$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AuthenticatedNavbarUserAvatar_data" | "NotificationMenu_fragment_User">;
  readonly " $fragmentType": "AuthenticatedNavbar_data";
};
export type AuthenticatedNavbar_data$key = {
  readonly " $data"?: AuthenticatedNavbar_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"AuthenticatedNavbar_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AuthenticatedNavbar_data",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AuthenticatedNavbarUserAvatar_data"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NotificationMenu_fragment_User"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "f619ea83e49eaaf1405c223aa2d4523f";

export default node;

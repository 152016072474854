/**
 * @generated SignedSource<<68393ff338b06f8917be15eba771b569>>
 * @relayHash e500727bee04a37a229048fb4e35d571
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID e500727bee04a37a229048fb4e35d571

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SeePendingNotificationsInput = {
  clientMutationId?: string | null | undefined;
};
export type NotificationMenu_SeePendingNotificationsMutation$variables = {
  input: SeePendingNotificationsInput;
};
export type NotificationMenu_SeePendingNotificationsMutation$data = {
  readonly seePendingNotifications: {
    readonly success: boolean | null | undefined;
  } | null | undefined;
};
export type NotificationMenu_SeePendingNotificationsMutation = {
  response: NotificationMenu_SeePendingNotificationsMutation$data;
  variables: NotificationMenu_SeePendingNotificationsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SeePendingNotificationsPayload",
    "kind": "LinkedField",
    "name": "seePendingNotifications",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationMenu_SeePendingNotificationsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NotificationMenu_SeePendingNotificationsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "e500727bee04a37a229048fb4e35d571",
    "metadata": {},
    "name": "NotificationMenu_SeePendingNotificationsMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "826b0c67b121a97896287414ebfeb806";

export default node;

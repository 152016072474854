import clsx from "clsx";
import React from "react";
import { twMerge } from "tailwind-merge";

const Bell = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={twMerge(clsx("", props?.className))}
    >
      <g opacity={0.8}>
        <path d="M4 7a7 7 0 0114 0v8H4V7z" fill="#0F0518" />
        <path
          d="M1.5 15h19M15.5 19c-1.144.926-2.737 1.5-4.5 1.5s-3.356-.574-4.5-1.5"
          stroke="#0F0518"
          strokeWidth={2}
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
};

export default Bell;

import React from "react";

import NewLoading from "@components/NewLoading";

import css from "./NotificationActionBlock.module.css";

interface NotificationActionBlockInterface {
  onAccept?: () => void;
  onReject?: () => void;
  accepting?: boolean;
  rejecting?: boolean;
  showAccept?: boolean;
  showReject?: boolean;
  acceptLabel?: string;
  rejectLabel?: string;
}
const NotificationActionBlock: React.FC<NotificationActionBlockInterface> = ({
  accepting,
  onAccept,
  rejecting,
  onReject,
  showAccept = true,
  showReject = true,
  acceptLabel = "Accept",
  rejectLabel = "Deny",
}) => {
  return (
    <div className={css.actionBlock}>
      {showAccept && (
        <button className={css.btnAccept} onClick={onAccept} disabled={accepting}>
          {accepting ? <NewLoading className={css.loadingIcon} /> : acceptLabel}
        </button>
      )}
      {showReject && (
        <button className={css.btnDeny} onClick={onReject} disabled={rejecting}>
          {rejecting ? <NewLoading className={css.loadingIcon} /> : rejectLabel}
        </button>
      )}
    </div>
  );
};
export default NotificationActionBlock;

import Image from "next/image";
import React from "react";

import { cn } from "@libs/helper";

import styles from "./index.module.css";
import Tooltip, { TooltipPositionType } from "@components/shared/Tooltip";

interface UserAvatarIconProps {
  src: string;
  username: string;
  className?: string;
  tooltipPosition?: TooltipPositionType;
}

const UserAvatarIcon: React.FC<UserAvatarIconProps> = ({ src, username, className, tooltipPosition = "bottom" }) => {
  return (
    <Tooltip position={tooltipPosition} msg={username}>
      <Image
        src={src}
        alt={username + " avatar"}
        //title={username}
        width={32}
        height={32}
        className={cn(styles.wrapper, className)}
        crossOrigin="anonymous"
      />
    </Tooltip>
  );
};

export default UserAvatarIcon;

import { PreloadedQuery, useRelayEnvironment } from "react-relay";
import { type ConcreteRequest, type OperationType } from "relay-runtime";

import { SerializablePreloadedQuery } from "@relay/loadSerializableQuery";
import useSerializablePreloadedQuery from "@relay/useSerializablePreloadedQuery";

export type WrapperComponentProps<TQueryNode extends ConcreteRequest, TQuery extends OperationType, TExtraFields> = {
  preloadedQuery: SerializablePreloadedQuery<TQueryNode, TQuery>;
  queryRef: PreloadedQuery<TQuery, Record<string, unknown>>;
} & TExtraFields;

// Define the type for the props that the HOC will accept
export type ClientComponentProps<TQueryNode extends ConcreteRequest, TQuery extends OperationType, TExtraFields> = {
  preloadedQuery: SerializablePreloadedQuery<TQueryNode, TQuery>;
} & TExtraFields;

export interface WithClientComponentProps<TQuery extends OperationType> {
  queryRef: PreloadedQuery<TQuery, Record<string, unknown>>;
}

const withClientComponent = <TQueryNode extends ConcreteRequest, TQuery extends OperationType, TExtraFields = {}>(
  WrappedComponent: React.ComponentType<WrapperComponentProps<TQueryNode, TQuery, TExtraFields>>
) => {
  // It returns a new component
  const Component = (props: ClientComponentProps<TQueryNode, TQuery, TExtraFields>) => {
    const environment = useRelayEnvironment();

    const queryRef = useSerializablePreloadedQuery<TQueryNode, TQuery>(environment, props.preloadedQuery);

    // It renders the wrapped component with the additional props
    return <WrappedComponent {...props} queryRef={queryRef} />;
  };

  // Fix: Add display name to the component
  Component.displayName = `withClientComponent(${
    WrappedComponent.displayName || WrappedComponent.name || "Component"
  })`;

  return Component;
};

export default withClientComponent;

import React, { ReactNode, createElement } from "react";
import css from "./Tooltip.module.css";
import clsx from "clsx";
import useMediaQuery from "@hooks/useMediaQuery";

interface indicatorSVGInterface {
  className?: string;
}
const indicatorSVG = ({ className = "" }: indicatorSVGInterface) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="12" height="6" viewBox="0 0 12 6" fill="none">
    <path
      d="M12 8.93779e-08L11.9367 8.73673e-08C11.3432 6.84998e-08 10.7803 0.263646 10.4003 0.719631L6.76822 5.07814C6.36843 5.55789 5.63157 5.55789 5.23178 5.07813L1.59969 0.71963C1.2197 0.263645 0.65681 -4.47015e-07 0.0632505 -5.63743e-07L0 -5.76181e-07L12 8.93779e-08Z"
      fill="#19191A"
    />
  </svg>
);

export type TooltipPositionType = "auto" | "top" | "bottom" | "right" | "left" | "hide";

interface TooltipInterface {
  msg?: ReactNode;
  children?: ReactNode;
  position?: TooltipPositionType;
}
const Tooltip = ({ msg, position = "top", children }: TooltipInterface) => {
  const isDesktop = useMediaQuery("only screen and (min-width : 769px)");

  let pos = position;
  if (position == "auto") {
    if (isDesktop) {
      pos = "right";
    } else {
      pos = "bottom";
    }
  }

  return (
    <div className={clsx(css.wrapper, "")}>
      {children}
      {msg && (
        <div className={clsx(css.tooltip, css[`pos-${pos}`])}>
          {msg}
          {createElement(indicatorSVG, { className: clsx(css.indicator, css[`indicator-${pos}`]) })}
        </div>
      )}
    </div>
  );
};
export default Tooltip;

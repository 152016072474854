/**
 * @generated SignedSource<<20ae170a5c57eba79cdd33a09aae673b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RenderRangesEntity$data = {
  readonly __typename: string;
  readonly downloadsCount?: number | null | undefined;
  readonly globalName?: string;
  readonly id: string;
  readonly likersCount?: number;
  readonly name?: string;
  readonly owner?: {
    readonly globalName: string;
  };
  readonly package?: {
    readonly downloadsCount: number | null | undefined;
    readonly icon: string;
    readonly id: string;
    readonly likersCount: number;
    readonly name: string;
    readonly owner: {
      readonly globalName: string;
    };
    readonly packageName: string;
    readonly viewerHasLiked: boolean;
    readonly viewerIsWatching: boolean;
    readonly watchersCount: number;
  };
  readonly slug?: string;
  readonly username?: string;
  readonly version?: string;
  readonly viewerHasLiked?: boolean;
  readonly viewerIsWatching?: boolean;
  readonly watchersCount?: number;
  readonly " $fragmentType": "RenderRangesEntity";
};
export type RenderRangesEntity$key = {
  readonly " $data"?: RenderRangesEntity$data;
  readonly " $fragmentSpreads": FragmentRefs<"RenderRangesEntity">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "likersCount",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "downloadsCount",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "globalName",
    "storageKey": null
  }
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "owner",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "watchersCount",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerHasLiked",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerIsWatching",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RenderRangesEntity",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        }
      ],
      "type": "User",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        (v5/*: any*/),
        (v6/*: any*/),
        (v7/*: any*/),
        (v8/*: any*/)
      ],
      "type": "Package",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "version",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Package",
          "kind": "LinkedField",
          "name": "package",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "icon",
              "storageKey": null
            },
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "packageName",
              "storageKey": null
            },
            (v5/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "type": "PackageVersion",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v4/*: any*/),
      "type": "Namespace",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        }
      ],
      "type": "BlogPost",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v1/*: any*/),
        (v5/*: any*/)
      ],
      "type": "DeployApp",
      "abstractKey": null
    }
  ],
  "type": "Node",
  "abstractKey": "__isNode"
};
})();

(node as any).hash = "7a005422dd5cd5b796a8f566faa3d592";

export default node;

import ConfidentialityTag from "@components/PackagePage/ConfidentialityTag";
import Link from "next/link";
import React from "react";
import clsx from "clsx";
import css from "./PackageNameExt.module.css";

interface PackageNameExtInterface {
  displayName: { owner: string; packageName: string };
  // versions?: MenuItemDataInterface[]
  version?: string;
  showPrivacy?: boolean;
  isPrivate?: boolean;
  linking?: "package" | "owner-package" | "nolink";
  onVersionChange?: (version: string) => void;
  nogap?: boolean;
  className?: string;
}
const PackageNameExt = ({
  displayName,
  showPrivacy,
  isPrivate,
  linking = "nolink",
  nogap = false,
  version,
  className,
}: PackageNameExtInterface) => {
  return (
    <div className={clsx(css.wrapper, className)}>
      {linking == "nolink" && (
        <div className={clsx(css.pkgDispName, { [css.nogap]: nogap })}>
          <span className={css.pkgName}>{displayName.owner}</span>
          <span className={css.pkgNameSep}>/</span>
          <span className={css.pkgName}>{displayName.packageName}</span>
        </div>
      )}
      {linking == "package" && (
        <Link
          className={clsx(css.pkgDispName, { [css.nogap]: nogap })}
          href={`/${displayName.owner}/${displayName.packageName}`}
        >
          <span className={css.pkgName}>{displayName.owner}</span>
          <span className={css.pkgNameSep}>/</span>
          <span className={css.pkgName}>{displayName.packageName}</span>
        </Link>
      )}
      {linking == "owner-package" && (
        <div className={clsx(css.pkgDispName, { [css.nogap]: nogap })}>
          <Link href={`/${displayName.owner}`} className={css.pkgName}>
            {displayName.owner}
          </Link>
          <span className={css.pkgNameSep}>/</span>
          <Link href={`/${displayName.owner}/${displayName.packageName}`} className={css.pkgName}>
            {displayName.packageName}
          </Link>
        </div>
      )}
      <div className={css.pkgDetails}>
        {showPrivacy && <ConfidentialityTag isPrivate={isPrivate} />}
        {version && <span className={css.version}>{`v${version}`}</span>}
      </div>
    </div>
  );
};
export default PackageNameExt;

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import Image from "next/image";
import React from "react";
import { graphql, useLazyLoadQuery } from "react-relay";

import NotifIndicatorIcon from "@assets/icons/NotifIndicatorIcon";
import RenderRanges from "@components/RenderRanges";
import { NotificationMenuItem_UserNotification$data } from "@generated/NotificationMenuItem_UserNotification.graphql";
import { RenderNotificationRanges_viewerQuery } from "@generated/RenderNotificationRanges_viewerQuery.graphql";
import { RenderRanges_data$key } from "@generated/RenderRanges_data.graphql";

import css from "./RenderNotificationRanges.module.css";

dayjs.extend(relativeTime);

const RenderNotificationRangesViewerQuery = graphql`
  query RenderNotificationRanges_viewerQuery {
    viewer {
      id
      avatar
    }
  }
`;

interface RenderNotificationRangesInterface {
  notifItemData: NotificationMenuItem_UserNotification$data | null;
  renderRangesFragmentRef: RenderRanges_data$key;
}
const RenderNotificationRanges = ({ notifItemData, renderRangesFragmentRef }: RenderNotificationRangesInterface) => {
  const viewerData = useLazyLoadQuery<RenderNotificationRanges_viewerQuery>(RenderNotificationRangesViewerQuery, {});

  const activityOwnerAvatar = notifItemData?.body.ranges.find((range) => range.entity?.avatar)?.entity?.avatar,
    viewerAvatar = viewerData?.viewer?.avatar!,
    notificationAvatar = !activityOwnerAvatar ? viewerAvatar : activityOwnerAvatar;

  return (
    <div className={css.wrapper}>
      <div className={css.content}>
        <div className={css.iconWrap}>
          <Image
            src={notificationAvatar}
            alt={notificationAvatar + " avatar"}
            width={32}
            height={32}
            className="h-[32px] w-[32px] min-w-[32px] rounded-full"
            crossOrigin="anonymous"
          />
          <NotifIndicatorIcon className={css.indicator} />
        </div>

        <div className={css.notifWrap}>
          <div className={css.messageBlock}>
            <div>
              <RenderRanges fragmentRef={renderRangesFragmentRef} />
            </div>
          </div>

          <div className={css.time}>{dayjs(notifItemData?.createdAt).fromNow()}</div>
        </div>
      </div>
    </div>
  );
};
export default RenderNotificationRanges;

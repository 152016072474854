import React from "react";
import { graphql, useFragment } from "react-relay";

import AuthenticatedFooter from "@components/AuthenticatedFooter";
import AuthenticatedNavbar from "@components/AuthenticatedNavbar";
import Footer from "@components/Footer";
import PageSection from "@components/PageSection";
import { LayoutWrapper_data$key } from "@generated/LayoutWrapper_data.graphql";
import Header from "@layout/Header";

import styles from "./index.module.css";
import EmailSentModal from "@components/signup/email-sent-modal";
import { useLocalStorage } from "@hooks/useLocalStorage";

const LayoutWrapper_dataFragment = graphql`
  fragment LayoutWrapper_data on User {
    id
    ...AuthenticatedNavbar_data
    ...HeaderNew_viewer
  }
`;

interface LayoutWrapper {
  children: React.ReactNode;
  fragmentRef: LayoutWrapper_data$key | null;
}

const LayoutWrapper: React.FC<LayoutWrapper> = ({ children, fragmentRef }) => {
  const data = useFragment(LayoutWrapper_dataFragment, fragmentRef);
  let authed = !!data;
  const [showEmail, setShowEmail] = useLocalStorage<boolean>("HAS_JUST_SIGNED_UP", false);

  const handleDismissEmailModal = () => {
    setShowEmail(false);
  };

  return (
    <main className="flex min-h-screen flex-col">
      {authed ? (
        <PageSection classNameForWrapper={styles.headerWrapperContainer} className={styles.headerWrapper}>
          <AuthenticatedNavbar fragmentRef={data!} />
          <EmailSentModal open={showEmail} onClose={handleDismissEmailModal} />
        </PageSection>
      ) : (
        <Header viewer={data!} />
      )}
      <div className="mx-auto grid min-w-full flex-1 flex-grow grid-cols-1 grid-rows-1 items-start">{children}</div>
      {authed ? (
        <PageSection classNameForWrapper={styles.footerWrapper} withTopBorder>
          <AuthenticatedFooter />
        </PageSection>
      ) : (
        <Footer />
      )}
    </main>
  );
};

export default LayoutWrapper;

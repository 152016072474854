"use client";

import Link from "next/link";
import { usePathname } from "next/navigation";
import React, { useState } from "react";
import { graphql, useFragment } from "react-relay";

import Hamburger2Bars from "@assets/icons/Hamburger2Bars";
import DevelopersNavbarItemHoverContent from "@components/DevelopersNavbarItemHoverContent";
import HeaderSearch from "@components/HeaderSearch";
import WasmerBrandingLogo from "@components/WasmerBrandingLogo";
import { AuthenticatedNavbar_data$key } from "@generated/AuthenticatedNavbar_data.graphql";

import AuthenticatedMobileMenu from "./AuthenticatedMobileMenu";
import AuthenticatedNavbarUserAvatar from "./AuthenticatedNavbarUserAvatar";
import BellForNotifications from "./BellForNotifications";
import NavigationLink from "./NavigationLink";

const AuthenticatedNavbar_data = graphql`
  fragment AuthenticatedNavbar_data on User {
    ...AuthenticatedNavbarUserAvatar_data
    ...NotificationMenu_fragment_User
  }
`;

interface AuthenticatedNavbarProps {
  fragmentRef: AuthenticatedNavbar_data$key;
}

const AuthenticatedNavbar: React.FC<AuthenticatedNavbarProps> = ({ fragmentRef }) => {
  const data = useFragment(AuthenticatedNavbar_data, fragmentRef),
    pathname = usePathname(),
    [isDevelopersItemHovered, setIsDevelopersItemHovered] = useState(false);

  const dashboardNavigationLinkIsActive = pathname === "/",
    // exploreNavigationLinkIsActive = pathname === "/explore",
    blogNavigationLinkIsActive = pathname.startsWith("/posts"),
    templatesNavigationLinkIsActive = pathname.startsWith("/templates"),
    handleDevelopersItemMouseEnter = () => {
      setIsDevelopersItemHovered(true);
    },
    handleDevelopersItemMouseLeave = () => {
      setTimeout(() => {
        setIsDevelopersItemHovered(false);
      }, 500);
    };

  const [openMobileMenu, setOpenMobileMenu] = React.useState(false);
  const handleOpen = () => setOpenMobileMenu(true);
  const handleClose = () => setOpenMobileMenu(false);

  return (
    <header className="flex w-full items-center justify-between gap-[24px] py-[20px]">
      <div className="flex items-center justify-center gap-[24px]">
        <Link href="/">
          <WasmerBrandingLogo className="h-[36px] w-[30px]" />
        </Link>
        <div className="hidden items-center justify-center gap-[24px] tablet:flex">
          <NavigationLink href="/" active={dashboardNavigationLinkIsActive}>
            Dashboard
          </NavigationLink>
          {/* <NavigationLink href="/explore" active={exploreNavigationLinkIsActive}>
            Explore
          </NavigationLink> */}
          <div className="relative z-[5]">
            <NavigationLink onMouseEnter={handleDevelopersItemMouseEnter} onMouseLeave={handleDevelopersItemMouseLeave}>
              Developers
            </NavigationLink>
            <DevelopersNavbarItemHoverContent show={isDevelopersItemHovered} />
          </div>
          <NavigationLink href="/posts" active={blogNavigationLinkIsActive}>
            Blog
          </NavigationLink>
          <NavigationLink href="/templates" active={templatesNavigationLinkIsActive}>
            Templates
          </NavigationLink>
        </div>
      </div>
      <div className="hidden flex-1 items-center justify-end gap-[24px] transition-all tablet:flex">
        <HeaderSearch className="right-0" key="header-search" />

        <BellForNotifications fragmentRef={data} />
        <AuthenticatedNavbarUserAvatar fragmentRef={data} />
      </div>
      <div className="flex items-center justify-center gap-[24px] tablet:hidden">
        <BellForNotifications fragmentRef={data} />
        <button onClick={handleOpen}>
          <Hamburger2Bars className="h-auto w-[22px] min-w-[22px]" />
        </button>
        <AuthenticatedMobileMenu
          open={openMobileMenu}
          onClose={handleClose}
          searchRender={<HeaderSearch key="header-search" />}
          fragmentRef={data}
        />
      </div>
    </header>
  );
};

export default AuthenticatedNavbar;

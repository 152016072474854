import Cookies from "js-cookie";
import Link from "next/link";
import React from "react";
import { graphql, useFragment } from "react-relay";

import UserAvatarIcon from "@components/UserAvatarIcon";
import { AuthenticatedNavbarUserAvatar_data$key } from "@generated/AuthenticatedNavbarUserAvatar_data.graphql";
import { Listbox } from "@headlessui/react";
import { DISCORD_INVITE } from "@utils/constants";

export const AuthenticatedNavbarUserAvatar_dataFragment = graphql`
  fragment AuthenticatedNavbarUserAvatar_data on User {
    username
    avatar
  }
`;

interface AuthenticatedNavbarUserAvatarProps {
  fragmentRef: AuthenticatedNavbarUserAvatar_data$key;
}

const AuthenticatedNavbarUserAvatar: React.FC<AuthenticatedNavbarUserAvatarProps> = ({ fragmentRef }) => {
  const data = useFragment(AuthenticatedNavbarUserAvatar_dataFragment, fragmentRef);

  const removeTOSFromStorage = () => {
    localStorage.removeItem("show_tos_modal_" + data.username);
  };

  const handleLogout = () => {
    Cookies.remove("auth_token");
    Cookies.remove("username");
    removeTOSFromStorage();
    window.location.href = "/";
  };
  return (
    <div className="relative">
      <Listbox>
        <Listbox.Button className="items-right flex font-medium text-[#B5B2B7]">
          <UserAvatarIcon src={data?.avatar} username={data?.username} />
        </Listbox.Button>
        <Listbox.Options className="absolute left-2/4 top-9 z-20 min-w-[100px] -translate-x-[50%] rounded-xl border border-[#cecdd2] bg-[#FBFBFC] py-2 shadow-xl">
          <Listbox.Option key="username" value="username" disabled>
            <p className="block cursor-pointer whitespace-nowrap px-2 py-1.5">
              Signed in as
              <br />
              <Link href={`/${data?.username}`}>{data?.username}</Link>
            </p>
          </Listbox.Option>
          {[
            { title: "Your Profile", url: `/${data?.username}` },
            { title: "Settings", url: "/settings/profile" },
          ]?.map((option) => (
            <Listbox.Option key={option?.title} value={option?.title}>
              <Link href={option?.url}>
                <span className="block cursor-pointer whitespace-nowrap px-5 py-1.5 font-medium hover:bg-gray-100">
                  {option?.title}
                </span>
              </Link>
            </Listbox.Option>
          ))}
          <Listbox.Option key="discord" value="discord">
            <Link href={DISCORD_INVITE} target="_blank">
              <span className="block cursor-pointer whitespace-nowrap px-5 py-1.5 font-medium hover:bg-gray-100">
                Join Discord
              </span>
            </Link>
          </Listbox.Option>
          <Listbox.Option key="logout" value="logout">
            <button
              onClick={handleLogout}
              className="block w-full cursor-pointer whitespace-nowrap px-5 py-1.5 text-start font-medium hover:bg-gray-100"
            >
              Sign out
            </button>
          </Listbox.Option>
        </Listbox.Options>
      </Listbox>
    </div>
  );
};

export default AuthenticatedNavbarUserAvatar;

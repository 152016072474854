/**
 * @generated SignedSource<<9c6a5be5de32b3525ba9778c841d5cd9>>
 * @relayHash c0caa0c2f131434c228c1a2303937051
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID c0caa0c2f131434c228c1a2303937051

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RemovePackageCollaboratorInviteInput = {
  clientMutationId?: string | null | undefined;
  inviteId: string;
};
export type NotificationItemInvitCollabPkg_RejectInviteMutation$variables = {
  input: RemovePackageCollaboratorInviteInput;
};
export type NotificationItemInvitCollabPkg_RejectInviteMutation$data = {
  readonly removePackageCollaboratorInvite: {
    readonly package: {
      readonly id: string;
      readonly pendingInvites: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
          } | null | undefined;
        } | null | undefined>;
      };
    };
  } | null | undefined;
};
export type NotificationItemInvitCollabPkg_RejectInviteMutation = {
  response: NotificationItemInvitCollabPkg_RejectInviteMutation$data;
  variables: NotificationItemInvitCollabPkg_RejectInviteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RemovePackageCollaboratorInvitePayload",
    "kind": "LinkedField",
    "name": "removePackageCollaboratorInvite",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Package",
        "kind": "LinkedField",
        "name": "package",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PackageCollaboratorInviteConnection",
            "kind": "LinkedField",
            "name": "pendingInvites",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PackageCollaboratorInviteEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PackageCollaboratorInvite",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationItemInvitCollabPkg_RejectInviteMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NotificationItemInvitCollabPkg_RejectInviteMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": "c0caa0c2f131434c228c1a2303937051",
    "metadata": {},
    "name": "NotificationItemInvitCollabPkg_RejectInviteMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "4d0576d9b1d6b94bffcae1f78ee26f81";

export default node;

import clsx from "clsx";
import React from "react";
import { twMerge } from "tailwind-merge";

const Lock = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={twMerge(clsx("stroke-[#B23112]", props?.className))}
    >
      <path
        d="M12.667 4.805H3.333c-.736 0-1.333.582-1.333 1.3v3.1a6 6 0 1012 0v-3.1c0-.718-.597-1.3-1.333-1.3z"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8 11.203a2 2 0 100-4 2 2 0 000 4z" fill="#B23112" />
      <path
        d="M11.2 4.805c0-2.179-.64-4-3.2-4s-3.2 1.821-3.2 4h6.4z"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Lock;

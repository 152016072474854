import clsx from "clsx";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import Image from "next/image";
import React, { useCallback, useState } from "react";
import { graphql, useFragment, useMutation } from "react-relay";

import NotifIndicatorIcon from "@assets/icons/NotifIndicatorIcon";
import PackageTag from "@components/PackageCard/PackageTag";
import { ToasterAlertDefault, ToasterAlertError } from "@components/shared/ToasterAlert";
import { NotificationItemInvitCollabPkg_AcceptInviteMutation } from "@generated/NotificationItemInvitCollabPkg_AcceptInviteMutation.graphql";
import { NotificationItemInvitCollabPkg_RejectInviteMutation } from "@generated/NotificationItemInvitCollabPkg_RejectInviteMutation.graphql";
import { NotificationItemInvitCollabPkg_UserNotificationKindIncomingPackageInvite$key } from "@generated/NotificationItemInvitCollabPkg_UserNotificationKindIncomingPackageInvite.graphql";

import NotificationActionBlock from "../NotificationActionBlock";
import css from "./NotificationItemInvitCollabPkg.module.css";

dayjs.extend(relativeTime);

const notifInvCollabPkg_Fragment = graphql`
  fragment NotificationItemInvitCollabPkg_UserNotificationKindIncomingPackageInvite on UserNotification {
    id
    icon
    seenState
    createdAt
    kind {
      ... on UserNotificationKindIncomingPackageInvite {
        packageInvite {
          id
          createdAt
          declinedBy {
            globalName
            avatar
          }
          approvedBy {
            globalName
            avatar
          }
          requestedBy {
            globalName
            avatar
          }
          package {
            owner {
              globalName
            }
            packageName
            icon
          }
        }
      }
    }
  }
`;

interface NotificationItemInvitCollabPkgInterface {
  notifPkgRef: NotificationItemInvitCollabPkg_UserNotificationKindIncomingPackageInvite$key | null;
}

const NotificationItemInvitCollabPkg = ({ notifPkgRef }: NotificationItemInvitCollabPkgInterface) => {
  const notifPkgData = useFragment(notifInvCollabPkg_Fragment, notifPkgRef);

  const [actionTaken, setActionTaken] = useState<"waiting" | "accepted" | "rejected">("waiting");

  const [acceptInvit, acceptingInvit] = useMutation<NotificationItemInvitCollabPkg_AcceptInviteMutation>(graphql`
    mutation NotificationItemInvitCollabPkg_AcceptInviteMutation($input: AcceptPackageCollaboratorInviteInput!) {
      acceptPackageCollaboratorInvite(input: $input) {
        packageCollaboratorInvite {
          id
          approvedBy {
            username
          }
          package {
            id
          }
        }
      }
    }
  `);

  const [rejectInvit, rejectingInvit] = useMutation<NotificationItemInvitCollabPkg_RejectInviteMutation>(graphql`
    mutation NotificationItemInvitCollabPkg_RejectInviteMutation($input: RemovePackageCollaboratorInviteInput!) {
      removePackageCollaboratorInvite(input: $input) {
        package {
          id
          pendingInvites {
            edges {
              node {
                id
              }
            }
          }
        }
      }
    }
  `);

  const handleOnAcceptInvit = useCallback(() => {
    const variables = {
      input: { inviteId: notifPkgData?.kind?.packageInvite?.id! },
    };
    acceptInvit({
      variables,
      onCompleted: (_response, errors) => {
        if (errors && errors.length) {
          ToasterAlertError({ message: errors[0].message });
        } else {
          ToasterAlertDefault({ message: "Package invitation accepted" });
          setActionTaken("accepted");
        }
      },
      onError: (error) => {
        console.error(error);
        ToasterAlertError({ message: "An error while accepting invitation" });
      },
    });
  }, [acceptInvit, notifPkgData?.kind?.packageInvite?.id]);

  const handleOnRejectInvit = useCallback(() => {
    const variables = {
      input: { inviteId: notifPkgData?.kind?.packageInvite?.id! },
    };
    rejectInvit({
      variables,
      onCompleted: (_response, errors) => {
        if (errors && errors.length) {
          ToasterAlertError({ message: errors[0].message });
        } else {
          ToasterAlertDefault({ message: "You rejected the invitation" });
          setActionTaken("rejected");
        }
      },
      onError: (error) => {
        console.error(error);
        ToasterAlertError({ message: "An error while rejecting invitation" });
      },
    });
  }, [rejectInvit, notifPkgData?.kind?.packageInvite?.id]);

  return (
    <div className={css.wrapper}>
      <div className={css.content}>
        <div className={css.iconWrap}>
          {notifPkgData?.kind?.packageInvite?.requestedBy?.avatar! && (
            <Image
              className={css.icon}
              src={notifPkgData?.kind?.packageInvite?.requestedBy?.avatar!}
              width={32}
              height={32}
              alt="user"
            />
          )}
          <NotifIndicatorIcon
            className={clsx(css.indicator, {
              ["!important hidden"]: notifPkgData?.seenState != "UNSEEN",
            })}
          />
        </div>

        <div className={css.notifWrap}>
          <div className={css.messageBlock}>
            <div className={css.messageCont}>
              <span className={css.username}>{notifPkgData?.kind?.packageInvite?.requestedBy.globalName}</span>
              <span className={css.message}>invited you to collaborate in</span>
              <PackageTag
                icon={notifPkgData?.kind?.packageInvite?.package.icon!}
                owner={notifPkgData?.kind?.packageInvite?.package.owner.globalName!}
                packageName={notifPkgData?.kind?.packageInvite?.package.packageName!}
                linking="package"
                iconSize={22}
              />
            </div>
          </div>

          <div className={css.time}>{dayjs(notifPkgData?.createdAt).fromNow()}</div>
          {actionTaken == "waiting" && (
            <NotificationActionBlock
              accepting={acceptingInvit}
              rejecting={rejectingInvit}
              onAccept={handleOnAcceptInvit}
              onReject={handleOnRejectInvit}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default NotificationItemInvitCollabPkg;

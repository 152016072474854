import React from "react";

import Lock from "@assets/Lock";
import World from "@assets/World";
import { cn } from "@libs/helper";

import styles from "./index.module.css";

interface ConfidentialityTagProps {
  isPrivate?: boolean;
}
const ConfidentialityTag: React.FC<ConfidentialityTagProps> = ({ isPrivate }) => {
  return (
    <div
      className={cn(styles.wrapper, isPrivate && styles.wrapperPrivate)}
      data-cy={`pkg-privacy-${isPrivate ? "private" : "public"}`}
    >
      {isPrivate ? <Lock /> : <World />}
      <span className={styles.label}>{isPrivate ? "Private" : "Public"}</span>
    </div>
  );
};

export default ConfidentialityTag;

import React from "react";
import Link from "next/link";

import { cn } from "@libs/helper";

const Logo = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 29 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cn("", props?.className)}
    >
      <g clipPath="url(#prefix__clip0_1268_12249)" fill="#0F0518">
        <path d="M8.908 13.95v.1c0 1.187-.746 1.704-1.662 1.157-.917-.546-1.662-1.952-1.662-3.138v-.1L0 8.636v18.719l14.5 8.645V17.28L8.908 13.95z" />
        <path d="M16.158 9.629v.101c0 1.186-.746 1.704-1.662 1.157-.917-.547-1.662-1.952-1.662-3.138v-.101L7.25 4.32v6.697l8.88 5.296v12.023l5.62 3.352V12.97l-5.592-3.34z" />
        <path d="M23.408 5.313v.101c0 1.187-.746 1.704-1.662 1.157-.916-.547-1.662-1.952-1.662-3.138v-.1L14.5 0v6.697l8.88 5.296v12.023L29 27.369V8.649l-5.592-3.336z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0_1268_12249">
          <path fill="#fff" d="M0 0h29v36H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

interface NavigationLinkProps {
  className?: string;
  href: string;
  children: React.ReactNode;
}
const NavigationLink = (props: NavigationLinkProps) => {
  return (
    <Link
      href={props.href}
      className={cn("font-gilroy text-[16px] font-[400] leading-[100%] text-[#0F0518]", props?.className)}
    >
      {props.children}
    </Link>
  );
};

const AuthenticatedFooter: React.FC = () => {
  return (
    <div className="flex w-full flex-col flex-wrap items-center justify-between gap-[16px] opacity-50 mobile:flex-row">
      <div className="flex w-full flex-col items-start justify-center gap-[16px] mobile:w-auto mobile:flex-row mobile:items-center">
        <Logo className="h-[36px] w-[29px]" />
        <p className="whitespace-nowrap font-gilroy text-[16px] font-[400] leading-[100%] text-[#0F0518]">
          Making software universally accessible
        </p>
      </div>
      <div className="flex w-full items-start justify-start gap-[32px] mobile:w-auto mobile:items-center mobile:justify-center">
        <NavigationLink
          href="/policies/privacy"
          className="font-gilroy text-[16px] font-[400] leading-[100%] text-[#0F0518]"
        >
          Privacy
        </NavigationLink>
        <NavigationLink href="/terms" className="font-gilroy text-[16px] font-[400] leading-[100%] text-[#0F0518]">
          Terms
        </NavigationLink>
      </div>
    </div>
  );
};

export default AuthenticatedFooter;

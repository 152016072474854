/**
 * @generated SignedSource<<201ce9422d0bdf7908c82892a0432063>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type UserNotificationSeenState = "SEEN" | "SEEN_AND_READ" | "UNSEEN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type NotificationItemInvitCollabNsp_UserNotificationKindIncomingNamespaceInvite$data = {
  readonly createdAt: any;
  readonly icon: string | null | undefined;
  readonly id: string;
  readonly kind: {
    readonly namespaceInvite?: {
      readonly approvedBy: {
        readonly avatar: string;
        readonly globalName: string;
      } | null | undefined;
      readonly createdAt: any;
      readonly declinedBy: {
        readonly avatar: string;
        readonly globalName: string;
      } | null | undefined;
      readonly id: string;
      readonly namespace: {
        readonly avatar: string;
        readonly displayName: string | null | undefined;
        readonly globalId: string;
        readonly globalName: string;
      };
      readonly requestedBy: {
        readonly avatar: string;
        readonly globalName: string;
      };
    };
  } | null | undefined;
  readonly seenState: UserNotificationSeenState;
  readonly " $fragmentType": "NotificationItemInvitCollabNsp_UserNotificationKindIncomingNamespaceInvite";
};
export type NotificationItemInvitCollabNsp_UserNotificationKindIncomingNamespaceInvite$key = {
  readonly " $data"?: NotificationItemInvitCollabNsp_UserNotificationKindIncomingNamespaceInvite$data;
  readonly " $fragmentSpreads": FragmentRefs<"NotificationItemInvitCollabNsp_UserNotificationKindIncomingNamespaceInvite">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "globalName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avatar",
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  (v3/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NotificationItemInvitCollabNsp_UserNotificationKindIncomingNamespaceInvite",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "icon",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "seenState",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "kind",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "NamespaceCollaboratorInvite",
              "kind": "LinkedField",
              "name": "namespaceInvite",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "declinedBy",
                  "plural": false,
                  "selections": (v4/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "approvedBy",
                  "plural": false,
                  "selections": (v4/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "requestedBy",
                  "plural": false,
                  "selections": (v4/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Namespace",
                  "kind": "LinkedField",
                  "name": "namespace",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "globalId",
                      "storageKey": null
                    },
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "displayName",
                      "storageKey": null
                    },
                    (v3/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "UserNotificationKindIncomingNamespaceInvite",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserNotification",
  "abstractKey": null
};
})();

(node as any).hash = "bbae32a9e4808b706599fc06a47e30af";

export default node;

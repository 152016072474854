import clsx from "clsx";
import React from "react";
import { twMerge } from "tailwind-merge";

const Hamburger2Bars = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={twMerge(clsx("", props?.className))}
    >
      <g opacity={0.8} stroke="#0F0518" strokeWidth={2} strokeLinecap="round">
        <path d="M1 6h20M1 16h20" />
      </g>
    </svg>
  );
};

export default Hamburger2Bars;
